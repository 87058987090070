export default [
	{
		title: 'KEMITRAAN LUAR NEGERI',
		caption: 'Program penguatan dan pengembangan kemitraan PTPPV dengan mitra luar negeri yang bersifat kompetisi',
		image: require('../../Assets/Images/post/kemitraan_luar.png'),
		document: '10c. Booklet Program Penguatan Penguatan Manajemen Riset Luar Negeri (17042021).pdf',
		id: 42,
		href: '#beasiswa',
	},
	{
		title: 'KEWIRAUSAHAAN',
		caption:
			'Program penguatan ekosistem kewirausahaan di PTPPV yang bersifat kompetisi. Program ini dilaksanakan berbasis pada kemitraan antara PTPPV dengan DUDI',
		image: require('../../Assets/Images/post/kewirausahaan.png'),
		document: '8d. Program Magang Tenaga Kependidikan Perguruan Tinggi Penyelenggara Pendidikan Vokasi.pdf',
		id: 43,
		href: '#beasiswa',
	},
	{
		title: 'PENGUATAN KEHUMASAN PTV',
		caption:
			'Peningkatan kapasitas SDM vokasi yang dalam hal ini adalah pranata Humas di PTV Negeri melalui pelatihan dan sertifikasi bidang kehumasan',
		image: require('../../Assets/Images/post/kehumasan.png'),
		document: '8b. Booklet Program Sertifikasi Profesi Dosen Perguruan Tinggi Penyelenggara Pendidikan Vokasi.pdf',
		id: 44,
		href: '#beasiswa',
	},
]
