import { Button, Row, Col, Carousel, Popover } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AuthContext } from '../../Context/AuthContext'
import { ProgramContext } from '../../Context/ProgramContext'

import './Home.scss'
import API from '../../Services/API'
import Footer from '../../Components/Footer'
import { Fragment } from 'react'
import thousandSeparator from '../../Utils/thousandSeparator'
import moment from 'moment'
import NewHeader from '../../Components/NewHeader'

import { CaretLeftFilled } from '@ant-design/icons'

const buttonStyle = {
	textAlign: 'center',
	background: '#25284E',
	padding: '8px 32px',
	borderRadius: 32,
	fontSize: 14,
	color: '#FFF',
	fontWeight: 'normal',
	outline: 'none',
	border: 'none',
	cursor: 'pointer',
	marginBottom: 8,
}

const NewDetail = ({ history, match, ...props }) => {
	const auhtcontext = useContext(AuthContext)
	const programContext = useContext(ProgramContext)
	const { profile } = auhtcontext

	console.log({ props })

	const [detailPost, setDetailPost] = useState({ data: {}, isLoading: true })
	const [detailProgram, setDetailProgram] = useState({ data: {}, isLoading: true })

	const getPostDetail = async () => {
		try {
			const response = await API.getDetailPost(match.params.id)
			setDetailPost({ data: response.data, isLoading: false })
			if (response.data.type === '2') {
				getProgramDetail(response.data.reference)
			}
		} catch (error) {
			console.log(error)
			setDetailPost({ ...detailProgram, isLoading: false })
		}
	}

	const getProgramDetail = async id => {
		try {
			const response = await API.getProgramDetail(id)
			setDetailProgram({ data: response.data, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getPostDetail()
	}, [])

	return (
		<div className='new_home'>
			<div className='new_home_section-1'>
				<div style={{ maxWidth: 1536, margin: '0 auto' }}>
					<NewHeader />
					{detailPost.isLoading && detailProgram.isLoading ? null : (
						<div className='program_detail_card'>
							<div className='program_detail_card_header'>
								<h3>{detailPost.data.title}</h3>
							</div>
							<div className='program_detail_card_body'>
								<img src={detailPost.data.imageURL} alt='Program Image' />
								<div className='program_detail_card_body_text'>
									<p style={{ margin: 0 }}>{detailPost.data.body}</p>
								</div>
							</div>
							{detailPost.data.type === '1' ? (
								<Fragment>
									<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
										<div style={{ textAlign: 'center' }}>
											<a href={detailPost.data.reference} target='_blank' rel='noopener noreferrer'>
												<div
													style={{ textAlign: 'center', background: '#25284E', padding: '8px 32px', borderRadius: 32 }}>
													<p style={{ margin: 0, fontSize: 14, color: '#FFF', fontWeight: 'normal' }}>Lihat Program</p>
												</div>
											</a>
										</div>
									</div>
								</Fragment>
							) : (
								<Fragment>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<div style={{ textAlign: 'center' }}>
											<CaretLeftFilled
												style={{ marginLeft: '2rem', fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}
												onClick={() =>
													history.action === 'POP' ? (window.location.href = '/#beasiswa') : history.goBack()
												}
											/>
										</div>

										{/* <div style={{ textAlign: 'center' }}>
											<div
												style={{ textAlign: 'center', background: '#ececec', padding: '8px 32px', borderRadius: 32 }}>
												<p style={{ margin: 0, fontSize: 14, color: '#25284E', fontWeight: 'normal' }}>
													{moment(detailProgram.data.start, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')} S.D.{' '}
													{moment(detailProgram.data.expired, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}
												</p>
											</div>
										</div> */}

										<div style={{ textAlign: 'center', cursor: 'pointer' }}>
											<Popover
												content={
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														{detailProgram.data?.programFiles?.map(file => (
															<button style={buttonStyle} key={file.ID} onClick={() => window.open(file.realPath)}>
																{file.name.split('.')[0]}
															</button>
														))}
													</div>
												}
												trigger='click'>
												<div
													style={{ textAlign: 'center', background: '#25284E', padding: '8px 32px', borderRadius: 32 }}>
													<p style={{ margin: 0, fontSize: 14, color: '#FFF', fontWeight: 'normal' }}>Unduh Berkas</p>
												</div>
											</Popover>
										</div>

										<div style={{ textAlign: 'center' }}>
											<a href={`/program/${detailPost.data.reference}`} target='_blank' rel='noopener noreferrer'>
												<div
													style={{ textAlign: 'center', background: '#25284E', padding: '8px 32px', borderRadius: 32 }}>
													<p style={{ margin: 0, fontSize: 14, color: '#FFF', fontWeight: 'normal' }}>Ajukan Program</p>
												</div>
											</a>
										</div>
									</div>
								</Fragment>
							)}
						</div>
					)}
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default NewDetail
