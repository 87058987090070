import React, { createContext, useState, useEffect, useContext } from 'react'
import API from '../Services/API'
import { AuthContext } from './AuthContext'
import jwtDecode from 'jwt-decode'
export const ProgramContext = createContext()
export const ProgramContextConsumer = ProgramContext.Consumer

const ProgramContextProvider = ({ children }) => {
	const authContext = useContext(AuthContext)
	const { profile, institutionID } = authContext
	const [programs, setPrograms] = useState({ data: [], isLoading: true })
	const [cities, setCities] = useState({ data: [], isLoading: true, parameters: { keyword: '' } })
	const [submitting, setSubmitting] = useState(false)
	const [banks, setBanks] = useState({ data: [], isLoading: true })

	const [queryProgramPhase, setQueryProgramPhase] = useState({
		programID: '',
		order: 'desc',
		sort: 'ID',
		pageSize: 0,
		currentPage: 1,
	})
	const [programPhaseList, setProgramPhaseList] = useState({ data: [], isLoading: true, pagination: {} })
	const [discuss, setDiscuss] = useState({
		accountID: institutionID || '',
		programID: '',
		comment: '',
	})

	const [discussList, setDiscussList] = useState({ data: [], isLoading: true, pagination: {} })

	const [error, setError] = useState(null)

	const [queryDiscuss, setQueryDiscuss] = useState({
		programID: '',
		order: 'desc',
		sort: 'ID',
		pageSize: 0,
		currentPage: 1,
	})

	const [detailPhaseForm, setDetailPhaseForm] = useState({
		type: '',
		programPhaseID: '',
		nominal: '',
		update: '',
		problem: '',
		'receipts[]': [],
		'activityPhotos[]': [],
		'reports[]': [],
	})

	const [institutionReq, setInstitutionReq] = useState({ data: [], isLoading: true })
	const [institutionReqParams, setInstitutionReqParams] = useState({})

	const fetchInstitutionReq = async () => {
		try {
			const response = await API.getProgramReqruitment(institutionReqParams)
			setInstitutionReq({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchInstitutionReq()
	}, [institutionReqParams])

	const getProgram = async () => {
		try {
			setPrograms({ ...programs, isLoading: true })
			const response = await API.getPrograms()
			setPrograms({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
			setPrograms({ ...programs, isLoading: false })
		}
	}

	const fetchCities = async () => {
		try {
			const response = await API.getCity(cities.parameters)
			if (response.code === 200) {
				setCities({
					...cities,
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchBanks = async () => {
		try {
			const response = await API.getAllBank()
			if (response.code === 200) {
				setBanks({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchCities()
	}, [cities.parameters])

	useEffect(() => {
		getProgram()
		fetchBanks()
	}, [])

	useEffect(() => {
		if (queryDiscuss.programID) {
			fetchDiscuss()
		}
	}, [queryDiscuss])

	const fetchDiscuss = async () => {
		try {
			const response = await API.getDiscusses(queryDiscuss)

			setDiscussList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	// useEffect(() => {
	// 	fetchProgramPhaseList()
	// }, [queryProgramPhase.programID])

	const fetchProgramPhaseList = async id => {
		try {
			const response = await API.getAllProgramPhase({ ...queryProgramPhase, programID: id })

			setProgramPhaseList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const createDiscuss = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createDiscuss({ ...discuss, accountID: institutionID })
					setSubmitting(false)
					setError(null)
					setDiscuss({
						...discuss,
						accountID: institutionID,
						comment: '',
					})
					fetchDiscuss()
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	return (
		<ProgramContext.Provider
			value={{
				programs,
				setPrograms,
				getProgram,

				discuss,
				setDiscuss,

				discussList,
				setDiscussList,

				programPhaseList,
				setProgramPhaseList,

				detailPhaseForm,
				setDetailPhaseForm,

				queryDiscuss,
				setQueryDiscuss,

				queryProgramPhase,
				setQueryProgramPhase,

				cities,
				setCities,

				createDiscuss,
				fetchProgramPhaseList,

				banks,
				setBanks,

				institutionReq,
				setInstitutionReq,
				institutionReqParams,
				setInstitutionReqParams,
				fetchInstitutionReq,
			}}>
			{children}
		</ProgramContext.Provider>
	)
}

export default ProgramContextProvider
