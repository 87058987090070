import React from 'react'
import { Steps, Card } from 'antd'
import moment from 'moment'

const HistoryAplicant = ({ history }) => {
	return (
		<div style={{ width: '100%' }}>
			<Steps progressDot direction='vertical' size='default' style={{ width: '100%' }}>
				{history.map((_history, index) => (
					<Steps.Step
						className='dudi-history-step'
						status={
							_history.isConfirmed === 2
								? 'error'
								: _history.isConfirmed === 1
								? 'finish'
								: _history.isConfirmed === 0
								? 'wait'
								: 'finish'
						}
						key={index}
						description={_history.reason ? <Card>{_history?.reason ?? ''}</Card> : null}
						title={
							moment(_history.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss') +
							' - ' +
							(_history.isConfirmed === 2
								? 'Ditolak'
								: _history.isConfirmed === 1
								? 'Diterima'
								: _history.isConfirmed === 0
								? 'Menunggu Persetujuan'
								: 'Menunggu Persetujuan')
						}></Steps.Step>
				))}
			</Steps>
		</div>
	)
}

export default HistoryAplicant
