import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Card, Row, Col, Form, Input, Checkbox, Alert, Button } from 'antd'
import BackgroundLogin from '../../Assets/Images/background-login.jpg'
import { AuthContext } from '../../Context/AuthContext'
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion'

const Login = ({ history }) => {
	const authContext = useContext(AuthContext)
	const { loginForm, setLoginForm, onLoginUser, errorLogin, setErrorLogin } = authContext

	if (localStorage.getItem('@user_token') || localStorage.getItem('@user_token')) {
		return <Redirect to='/' />
	}

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			style={{
				position: 'absolute',
				width: '70%',
				transform: 'translate(-50%, 0%)',
				top: '15%',
				left: '50%',
				marginBottom: 40,
			}}>
			<Card
				style={{ boxShadow: '0 0 15px -7px #9b9b9b', borderRadius: 4, overflow: 'hidden' }}
				bodyStyle={{ padding: 0 }}>
				<Row>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
						<ArrowLeftOutlined style={{ margin: '16px 0 0 16px' }} onClick={() => history.push('/')} />
						<div style={{ padding: 40 }}>
							<div style={{ position: 'relative' }}>
								<p style={{ textAlign: 'center', fontWeight: 700, fontSize: 22, color: '#14213d' }}>
									Masuk Ke Akun Anda
								</p>
								<span
									style={{
										borderBottom: '3px solid #14213d',
										width: '30%',
										position: 'absolute',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}></span>
							</div>

							<br />

							<Form
								onFinish={() => onLoginUser()}
								name={'Login'}
								style={{
									marginTop: 40,
								}}>
								<Form.Item name='email' rules={[{ required: true, message: 'Email Tidak Boleh Kosong' }]}>
									<Input
										placeholder={'Masukkan Email'}
										style={{
											padding: 10,
											borderRadius: 7,
										}}
										onChange={e => setLoginForm({ ...loginForm, username: e.target.value })}
									/>
								</Form.Item>

								<Form.Item name='password' rules={[{ required: true, message: 'Password Tidak Boleh Kosong' }]}>
									<Input.Password
										placeholder={'Masukkan Password'}
										style={{
											padding: 10,
											borderRadius: 7,
										}}
										onChange={e => setLoginForm({ ...loginForm, password: e.target.value })}
									/>
								</Form.Item>

								<Row>
									<Col xs={12} sm={12}>
										<Form.Item name='remember' valuePropName='checked'>
											<Checkbox
												onChange={e => setLoginForm({ ...loginForm, remember: e.target.checked })}
												style={{
													fontSize: 12,
													fontWeight: 600,
												}}>
												Ingat Saya
											</Checkbox>
										</Form.Item>
									</Col>
									<Col xs={12} sm={12} style={{ textAlign: 'right' }}>
										<p
											style={{
												fontSize: 12,
												fontWeight: 600,
												marginBottom: 0,
												paddingTop: 7,
											}}>
											Lupa Password ?
										</p>
									</Col>
								</Row>

								{!errorLogin ? null : (
									<Alert
										message={typeof errorLogin === 'string' ? errorLogin : errorLogin?.errors?.errors[0]}
										type='error'
										showIcon
										closable
										onClose={() => setErrorLogin(null)}
									/>
								)}

								<Form.Item>
									<p style={{ textAlign: 'center', marginTop: 20 }}>
										<button disabled={loginForm.loading} type='submit' className={'dudi-button'}>
											{loginForm.loading && <LoadingOutlined />} MASUK
										</button>
									</p>
								</Form.Item>
							</Form>
						</div>
					</Col>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 12 }}
						style={{
							backgroundImage: `url(${BackgroundLogin})`,
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							position: 'relative',
						}}>
						<div
							style={{
								background: 'rgba(20, 33, 61, .7)',
								width: '100%',
								height: '100%',
							}}>
							<div
								style={{
									paddingTop: '20%',
									paddingBottom: '20%',
									paddingLeft: '5%',
									paddingRight: '5%',
								}}>
								<div style={{ position: 'relative' }}>
									<h2 style={{ color: 'white', textAlign: 'center' }}>Selamat Datang</h2>

									<span
										style={{
											borderBottom: '3px solid white',
											width: '30%',
											position: 'absolute',
											left: '50%',
											transform: 'translate(-50%, -50%)',
										}}></span>
								</div>

								<p style={{ textAlign: 'center', color: 'white', marginTop: 40, lineHeight: 2, fontWeight: 300 }}>
									Selamat datang di Program Beasiswa Pendidikan Vokasi. Jika Anda tidak memiliki akun dan ingin
									bergabung dengan kami, silakan tekan tombol daftar di bawah ini.
								</p>

								<p style={{ textAlign: 'center', color: 'white', marginTop: 40 }}>
									<Link to={'/register'}>
										<button className={'dudi-button-white'}>Daftar</button>
									</Link>
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
		</motion.div>
	)
}

export default Login
