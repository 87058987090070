import React, { useContext, Fragment } from 'react'
import Header from '../../Components/Header'
import CustomLayout from '../../Components/CustomLayout'
import { Row, Col, Divider, Card, Pagination, Spin } from 'antd'
import ProgramCard from '../../Components/ProgramCard'
import { ProgramContext } from '../../Context/ProgramContext'
import { motion } from 'framer-motion'

const Program = () => {
	const programContext = useContext(ProgramContext)
	const { programs, setPrograms, getProgram } = programContext
	let easing = [0.6, -0.05, 0.01, 0.99]

	return (
		<div>
			<Header title='Program Pendidikan Vokasi' />

			<CustomLayout>
				<motion.div
					initial={{ opacity: 0, x: -100 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: easing }}>
					<Card className='program-card-container'>
						<Row justify='start' align='middle'>
							<Col sm={24} xs={24} md={18} lg={18}>
								<h1 className='big-title'>Daftar Program</h1>
							</Col>
						</Row>

						<Row
							gutter={[16, 0]}
							align='middle'
							justify={programs.isLoading || (!programs.isLoading && programs.data.length === 0) ? 'center' : 'start'}>
							{programs.isLoading ? (
								<Col>
									<Spin size='large' />
								</Col>
							) : programs.data.length === 0 ? (
								<Col>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'column',
											padding: '100px 0',
										}}>
										<h3>Silakan Daftar Program Sosialisasi</h3>
										<h1>PENGEMBANGAN PENILAIAN MUTU PENDIDIKAN TINGGI VOKASIBERSTANDAR INDUSTRI</h1>
									</div>
								</Col>
							) : (
								programs.data.map(program => (
									<Col sm={24} xs={24} md={8} lg={8} key={program.ID}>
										<ProgramCard
											nominal={program.nominal}
											navigateTo={`/program/${program.ID}`}
											image={program.imageURL}
											isVerivied
											title={program.name}
											description={program.description}
										/>
									</Col>
								))
							)}
						</Row>
						<br />
						{/* <Row justify='center'>
						<Col>
							<Pagination disabled defaultCurrent={3} total={100} showSizeChanger={false} />
						</Col>
					</Row> */}
					</Card>
				</motion.div>
			</CustomLayout>
		</div>
	)
}

export default Program
