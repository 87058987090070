import Axios from '../Utils/axios'
import queryString from 'query-string'

export default class API {
	static loginUser(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/access/login', formData, {
					headers: {
						Role: 'Institution',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkAccount() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post('/access/account')
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getPrograms(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/program?' + queryString.stringify(params))
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/program/' + id)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailInstitution(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/institution/' + id)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAplicants(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/program/applicant?' + queryString.stringify(params))
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getCity(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/city?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createInstitution(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/institution', formData, {
					headers: {
						Role: 'Institution',
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDiscusses(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/discuss?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createDiscuss(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/discuss`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createReplyDiscuss(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/discuss`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getAllProgramPhase(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/phase?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createDetailProgress(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('programPhaseID', body.programPhaseID)
				formData.append('nominal', body.nominal)
				formData.append('update', body.update)
				formData.append('problem', body.problem)

				Array.from(body['receipts[]']).forEach(data => {
					formData.append('receipts[]', data)
				})

				Array.from(body['activityPhotos[]']).forEach(data => {
					formData.append('activityPhotos[]', data)
				})

				Array.from(body['reports[]']).forEach(data => {
					formData.append('reports[]', data)
				})

				const response = await Axios.post(`/program/phase/detail`, formData)

				resolve(response.data)
			} catch (error) {
				console.log(error)
				reject(error.response.data)
			}
		})
	}

	static updateDetailProgress(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('nominal', body.nominal)
				formData.append('update', body.update)
				formData.append('problem', body.problem)

				Array.from(body['receipts[]']).forEach(data => {
					formData.append('receipts[]', data)
				})

				Array.from(body['activityPhotos[]']).forEach(data => {
					formData.append('activityPhotos[]', data)
				})

				Array.from(body['reports[]']).forEach(data => {
					formData.append('reports[]', data)
				})

				const response = await Axios.post(`/program/phase/detail/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				console.log(error)
				reject(error.response.data)
			}
		})
	}

	static applyProgramApplicant(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/program/applicant/apply`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateProfile(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/institution/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static updateProfileBank(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/institution/bank/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getAllBank(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/bank?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramReqruitment(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/requirement?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static changeProgramRequirement(body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/program/requirement/institution-requirement`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static updateInstitutionLegal(body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/institution/institution-legal`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static updateInstitutionIdentification(body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/institution/institution-identification`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static updateInstitutionPostalCode(body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/institution/institution-address`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static updateInstitutionNpwp(body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/institution/institution-npwp`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static uploadBudget(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData()

			Object.keys(body).forEach(obj => {
				formData.append(obj, body[obj])
			})
			try {
				const response = await Axios.post(`/program/budget/${id}/upload`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getProgramBudgetHistory(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/output?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetHistoryDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetSubOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/sub-output?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getPosts() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/post/all?deleted=0`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static getBooklets() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/booklet`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	/**
	 *
	 * @param {Number | String} id  ID Of Post
	 * @returns {Promise}
	 */
	static getDetailPost(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/post/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
