import React, { useState, useEffect, useContext, useRef } from 'react'
import { Steps, notification, Spin, Col, Row, Button } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../Context/AuthContext'
import API from '../../../Services/API'

const HistoryBudget = ({ id }) => {
	const budgetFile = useRef()
	const authContext = useContext(AuthContext)

	const [loadingUploadBudget, setLoadingUploadBdget] = useState(false)
	const [budgetProgramHistory, setBudgetProgramHistory] = useState({
		isLoading: true,
		data: [],
	})

	const fetchBudgetProgramHistory = async () => {
		try {
			setBudgetProgramHistory({ data: [], isLoading: true })

			const response = await API.getProgramBudgetHistory({
				sort: 'desc',
				order: 'ID',
				institutionID: authContext?.institutionID,
				programID: id,
			})
			setBudgetProgramHistory({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			setBudgetProgramHistory({ data: [], isLoading: false })
			notification.error({ message: 'Gagal mengambil data' })
		}
	}

	const onUploadBudget = async file => {
		try {
			setLoadingUploadBdget(true)
			await API.uploadBudget(id, { file })
			notification.success({ message: 'Sukses unggah berkas, silahkan tunggu respon dari admin' })
			setLoadingUploadBdget(false)
			fetchBudgetProgramHistory()
		} catch (error) {
			setLoadingUploadBdget(false)
			budgetFile.current.value = null
			notification.error({ message: error?.errors?.errors[0] ?? 'Gagal unggah berkas', duration: 3000 })
		}
	}

	useEffect(() => {
		fetchBudgetProgramHistory()
	}, [])

	return (
		<Row gutter={[16, 16]}>
			<Col span={18}>
				{budgetProgramHistory.isLoading ? (
					<div>
						<br />
						<Spin />
						<br />
					</div>
				) : (
					<div>
						<p>Status Revisi : </p>
						<Steps progressDot direction='vertical' size='default' style={{ width: '100%' }}>
							{budgetProgramHistory.data.map((programHisory, index) => (
								<Steps.Step
									className='dudi-history-step'
									status={
										programHisory.programApplicantBudgetResponse === null
											? 'wait'
											: programHisory.programApplicantBudgetResponse.status === 2
											? 'error'
											: programHisory.programApplicantBudgetResponse.status === 1
											? 'process'
											: 'wait'
									}
									subTitle={
										programHisory.programApplicantBudgetResponse === null
											? 'menunggu'
											: programHisory.programApplicantBudgetResponse.status === 2
											? 'ditolak'
											: programHisory.programApplicantBudgetResponse.status === 1
											? 'diterima'
											: 'review ulang'
									}
									description={
										programHisory.programApplicantBudgetResponse === null
											? ''
											: programHisory.programApplicantBudgetResponse.status === 2
											? programHisory.programApplicantBudgetResponse.applicantBudgetReason.reason
											: ''
									}
									title={
										<Link to={`${id}/budget-plan/${programHisory.ID}`} style={{ color: 'inherit' }}>{`Revisi ${
											budgetProgramHistory.data.length - index
										} (${moment(programHisory.created).format('DD MMM YYYY')})`}</Link>
									}></Steps.Step>
							))}
						</Steps>
					</div>
				)}
			</Col>

			<Col span={6}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
					<input
						style={{ display: 'none' }}
						ref={budgetFile}
						type='file'
						name='budgetFile'
						id='budgetFile'
						onChange={event => onUploadBudget(event.target.files[0])}
					/>

					<Button type='primary' onClick={() => budgetFile.current.click()} loading={loadingUploadBudget}>
						Unggah Rencana Anggaran
					</Button>
					<a
						href={`${process.env.REACT_APP_BASE_ASSET_URL}/program/template-rab.xlsx`}
						target='_blank'
						rel='noopener noreferrer'>
						<Button
							type='primary'
							onClick={() => {
								notification.info({ message: 'Harap tidak merubah contoh', duration: 20 })
							}}>
							Unduh Contoh
						</Button>
					</a>
				</div>
			</Col>
		</Row>
	)
}

export default HistoryBudget
