export default [
	{
		title: 'PROGRAM RISET KEILMUAN TERAPAN DALAM NEGERI - PT VOKASI',
		caption:
			'Program pendanaan riset terapan untuk menyelesaikan masalah yang berkaitan dengan isu ekonomi dan sosial; serta masalah riil yang dihadapi Dunia Usaha Dunia Industri (DUDI) dan masyarakat',
		image: require('../../Assets/Images/post/program_riset.png'),
		document: '10c. Booklet Program Penguatan Penguatan Manajemen Riset Luar Negeri (17042021).pdf',
		id: 34,
		href: '#beasiswa',
		isExternal: false,
	},
	{
		title: 'PROGRAM MAGANG TENAGA KEPENDIDIKAN PERGURUAN TINGGI PENYELENGGARA PENDIDIKAN VOKASI (PTPPV)',
		caption:
			'Program ini diperuntukan bagi tenaga kependidikanPranata Laboratorium Pendidikan (PLP) sebagai pembinaan yang bertujuan untuk meningkatkan kemampuan PLP',
		image: require('../../Assets/Images/post/program_magang.png'),
		document: '8d. Program Magang Tenaga Kependidikan Perguruan Tinggi Penyelenggara Pendidikan Vokasi.pdf',
		id: 35,
		href: 'https://kompetensi.sumberdaya.kemdikbud.go.id/',
		isExternal: true,
	},
	{
		title: 'PROGRAM SERTIFIKASI PROFESI DOSEN PERGURUAN TINGGI PENYELENGGARA PENDIDIKAN VOKASI (PTPPV)',
		caption:
			'Program pendidikan dan pelatihan vokasi berbasis industri bagi Dosen dan Tenaga Kependidikan ini berfokus pada sertikasi profesi.',
		image: require('../../Assets/Images/post/program_sertifikasi.png'),
		document: '8b. Booklet Program Sertifikasi Profesi Dosen Perguruan Tinggi Penyelenggara Pendidikan Vokasi.pdf',
		id: 36,
		href: 'https://kompetensi.sumberdaya.kemdikbud.go.id/',
		isExternal: true,
	},
	{
		title: 'PROGRAM MAGANG DOSEN PERGURUAN TINGGI PENYELENGGARA PENDIDIKAN VOKASI (PTPPV)',
		caption:
			'Program ini bersifat pembinaan melalui hubungan yang intensif antara peserta program magang dan tenaga pembinanya di Perguruan Tinggi Pembina (PTPembina) selama 2-4 bulan',
		image: require('../../Assets/Images/post/program_magang_dosen.png'),
		document: '7b. 7d. Program Magang Dosen Penyelenggara Pendidikan Vokasi.pdf',
		id: 37,
		href: 'https://kompetensi.sumberdaya.kemdikbud.go.id/',
		isExternal: true,
	},
	{
		title: 'PROGRAM BRIDGING COURSE VOKASI',
		caption:
			'Program penguatan kemampuan bahasa Inggris dan keterampilan akademik pendukung terstuktur bagi calon mahasiswa penerima beasiswa Kemendikbud agar dapat memenuhi persyaratan diterima di perguruan tinggi di luar negeri.',
		image: require('../../Assets/Images/post/program_bridging_course_1.png'),
		id: 38,
		href: '#beasiswa',
		isExternal: false,
	},
	{
		title: 'PROGRAM PROJECT BASED LEARNING/MAGANG BERSERTIFIKAT GURU SMK(DALAM NEGERI DAN LUAR NEGERI)',
		caption:
			'Program ini berfokus pada peningkatan kompetensi teknis bagi guru SMK untuk mendapatkan pengalaman secara langsung di industri baik di dalam negeri atau luar negeri',
		image: require('../../Assets/Images/post/program_project_base.png'),
		id: 39,
		href: '#beasiswa',
		isExternal: false,
	},

	{
		title:
			'PROGRAM PROJECT BASED LEARNING/PRAKTIKKERJA LAPANGAN BERSERTIFIKAT BAGI SISWA SMK(DALAM NEGERI DAN LUAR NEGERI)',
		caption:
			'Program ini berfokus pada peningkatan kompetensi teknis bagi peserta didik melalui pengalaman secara langsung di industri baik didalam negeri atau luar negeri',
		image: require('../../Assets/Images/post/program_project_base_1.png'),
		id: 40,
		href: '#beasiswa',
		isExternal: false,
	},
	{
		title: 'PROGRAM KAMPUS MERDEKA VOKASI 2021',
		caption:
			'Program Kampus Merdeka adalah beasiswa pendidikan jenjang D4/D3/D2 adalah Hak Belajar mahasiswa D4/ D3/D2 satu semester di Luar Program Studi diploma atau sarjana terapan di dalam negeri dan luar negeri',
		image: require('../../Assets/Images/post/program_kampus_merdeka.png'),
		document: '6b, 6f dan 6h Booklet Kampus Merdeka Vokasi.pdf',
		id: 41,
		href: 'https://kampusmerdeka.kemdikbud.go.id/',
		isExternal: true,
	},
]
