import React from 'react'
import ReactDOM, { hydrate, render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AuthContextProvider from './Context/AuthContext'
import ProgramContextProvider from './Context/ProgramContext'

ReactDOM.render(
	<AuthContextProvider>
		<ProgramContextProvider>
			<App />
		</ProgramContextProvider>
	</AuthContextProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
