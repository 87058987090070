import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({ data, index }) => {
	return (
		<div className='card__program'>
			<div
				style={{
					height: 54,
					width: 54,
					borderRadius: 54 / 2,
					background: '#FCD108',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#25284E',
					position: 'absolute',
					top: -12,
					left: -12,
					zIndex: 2,
				}}>
				<span style={{ fontSize: 32, fontWeight: 'bold' }}>{index + 1}</span>
			</div>
			<div>
				<img src={data.imageURL} alt='Card Header' />
			</div>
			<div className='card__program__body'>
				<h4 className='card__program__body__title'>{data.title}</h4>
				<p>{data.summary}</p>
				<div style={{ textAlign: 'center' }}>
					<Link
						to={{
							pathname: `/post/${data.ID}`,
						}}>
						<button className='card__program__body__btn' style={{ marginTop: '1rem' }}>
							Detail
						</button>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Card
