import React from 'react'
import KemdikbudImage from '../Assets/Images/kemdikbud.png'

const Footer = () => {
	return (
		<div className='new_footer'>
			<div>
				<img className='footer_cover' src={KemdikbudImage} alt='Logo Kemdikbud' />
			</div>
			<div className='footer_address'>
				<div>
					<h3>DIREKTORAT</h3>
					<h3>JENDERAL PENDIDIKAN VOKASI</h3>
				</div>
				<div style={{ maxWidth: 470, marginTop: 32 }}>
					<ul>
						<li>Telp : (021) 5725 061</li>
						<li>Fax : (021) 5725 484</li>
						<li>Email : vokasi@Kemdikbud.go.id</li>
						<li>Alamat : Alamat Jl. Jenderal Sudirman Gedung E Lantai III Senayan, Jakarta 10270</li>
					</ul>
				</div>
			</div>

			<button onClick={() => window.scrollTo(0, 0)}>
				<img src={require('../Assets/Images/arrow.png')} alt='Arrow' />
			</button>
		</div>
	)
}

export default Footer
