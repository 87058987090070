import React, { createContext, useState, useEffect } from 'react'
import API from '../Services/API'

export const AuthContext = createContext()
export const AuthContextConsumer = AuthContext.Consumer

const localUser = localStorage.getItem('@user_token')
const sessionUser = sessionStorage.getItem('@user_token')

const AuthContextProvider = ({ children }) => {
	const [loginForm, setLoginForm] = useState({
		username: '',
		password: '',
		remember: false,
		loading: false,
	})

	const [profile, setProfile] = useState(null)

	const [errorLogin, setErrorLogin] = useState(null)
	const [institutionID, setInstitutionId] = useState('')

	const [registerForm, setRegisterForm] = useState({
		name: '',
		email: '',
		phone: '',
		latitude: '',
		longitude: '',
		NPWP: '',
		managerName: '',
		cityID: '',
		password: '',
	})

	const getUserID = async () => {
		if (localUser) {
			try {
				await API.checkAccount()
			} catch (error) {
				if (error.response.data.code === 403) {
					localStorage.clear()
					window.location.href = '/'
				}
			}
		}
		if (sessionUser) {
			try {
				await API.checkAccount()
			} catch (error) {
				if (error.response.data.code === 403) {
					sessionStorage.clear()
					window.location.href = '/'
				}
			}
		}
	}

	const getUserProfile = async () => {
		try {
			if (localStorage.getItem('@institutionAccess')) {
				const json = JSON.parse(localStorage.getItem('@institutionAccess'))
				const profileResponse = await API.getDetailInstitution(json.institutionID)
				setInstitutionId(json.accountID)
				setProfile(profileResponse.data)
			}

			if (sessionStorage.getItem('@institutionAccess')) {
				const json = JSON.parse(sessionStorage.getItem('@institutionAccess'))
				const profileResponse = await API.getDetailInstitution(json.institutionID)
				setInstitutionId(json.accountID)

				setProfile(profileResponse.data)
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getUserID()
		getUserProfile()
	}, [])

	const onLoginUser = async () => {
		if (!loginForm.loading) {
			setLoginForm({ ...loginForm, loading: true })
			try {
				const response = await API.loginUser(loginForm)

				if (response.data.token && response.data.account.institutionAccess) {
					localStorage.setItem('@user_token', response.data.token)
					localStorage.setItem('@institutionAccess', JSON.stringify(response.data.account.institutionAccess))
					const profileResponse = await API.getDetailInstitution(response.data.account.institutionAccess.institutionID)
					setInstitutionId(response.data.account.institutionAccess.accountID)

					setProfile(profileResponse.data)
					window.location.href = '/'
				}

				setLoginForm({ username: '', password: '', remember: false, loading: false })
			} catch (error) {
				if (error.message === 'Network Error') {
					setErrorLogin(error.message)
					setLoginForm({ ...loginForm, loading: false })
				} else {
					setErrorLogin(error?.response?.data)
					setLoginForm({ ...loginForm, loading: false })
				}
			}
		}
	}

	return (
		<AuthContext.Provider
			value={{
				registerForm,
				setRegisterForm,

				loginForm,
				setLoginForm,
				onLoginUser,
				errorLogin,
				setErrorLogin,

				profile,
				setProfile,
				getUserProfile,

				institutionID,
				setInstitutionId,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
