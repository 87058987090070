import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header'
import CustomLayout from '../../Components/CustomLayout'
import { Row, Col, Divider, Table } from 'antd'
import API from '../../Services/API'
import { Link } from 'react-router-dom'

import thousandSeparator from '../../Utils/thousandSeparator'
import { render } from '@testing-library/react'

const Program = ({ history }) => {
	const [programs, setPrograms] = useState({ data: [], isLoading: true, pagination: {} })
	const [query, setQuery] = useState({ owned: 1, currentPage: 1, pageSize: 20 })

	const fetchPRograms = async () => {
		try {
			const response = await API.getPrograms(query)
			setPrograms({
				data: response.data.list,
				isLoading: false,
				pagination: response.data.pagination,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchPRograms()
	}, [query])

	const columns = [
		{
			title: 'Nama',
			dataIndex: 'name',
			render: (text, record) => <Link to={`/program/${record.ID}`}>{text}</Link>,
			align: 'center',
			width: '20%',
		},
		{
			title: 'Nominal',
			dataIndex: 'nominal',
			align: 'center',
			render: (text, record) => <span>Rp. {thousandSeparator(text)}</span>,
			width: '20%',
		},

		{
			title: 'Deskripsi',
			dataIndex: 'description',
			align: 'center',
		},

		{
			title: 'Status',
			align: 'center',
			render: data => {
				return (
					<div>
						<span
							className={`dudi-tags ${
								data.isOwned === null || data.isOwned === 0
									? 'pending'
									: data.isOwned === 1
									? 'status'
									: data.isOwned === 2
									? 'ditolak'
									: ''
							} `}>
							{data.isOwned === 1 ? 'Diterima' : data.isOwned === 2 ? 'Ditolak' : 'Menunggu Persetujuan'}
						</span>
					</div>
				)
			},
		},
	]

	return (
		<div>
			<Header title='Program' onBack={() => (history.action === 'POP' ? history.push('/') : history.goBack())} />

			<CustomLayout>
				<Row justify='space-between' align='middle'>
					<Col sm={24} xs={24} md={12}>
						<h1 className='big-title'>Daftar Program Yang Sudah Di Ajukan</h1>
					</Col>
				</Row>

				<Divider />

				<Table
					pagination={{
						defaultPageSize: 5,
						pageSize: query.pageSize,
						total: programs.pagination.itemCount ?? 5,
						onChange: page => setQuery({ ...query, currentPage: page }),
						current: query.currentPage,
						defaultCurrent: 1,
					}}
					loading={programs.isLoading}
					columns={columns}
					dataSource={programs.data.map(program => ({
						key: program.ID,
						...program,
					}))}
					bordered
				/>
			</CustomLayout>
		</div>
	)
}

export default Program
