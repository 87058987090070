import Axios from 'axios'

let token
let role

try {
	if (localStorage.getItem('@user_token')) {
		token = localStorage.getItem('@user_token')
		role = 'Institution'
	}
	if (sessionStorage.getItem('@user_token')) {
		token = sessionStorage.getItem('@user_token')
	}
} catch (error) {
	token = null
}

Axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL
Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
Axios.defaults.headers.common['Role'] = token ? `Institution` : null
Axios.defaults.headers.post['Content-Type'] = `multipart/form-data`

export default Axios
