import React, { useContext, Fragment, useState } from 'react'
import { Checkbox, Row, Col, Button, Divider, Spin, notification, Popover } from 'antd'
import {
	QuestionCircleOutlined,
	FileOutlined,
	FilePdfOutlined,
	FileWordOutlined,
	FileExcelOutlined,
	FileImageOutlined,
	FilePptOutlined,
	InfoCircleOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons'
import { ProgramContext } from '../../../Context/ProgramContext'
import { useEffect } from 'react'
import API from '../../../Services/API'
import _ from 'lodash'

const Requirements = ({ match }) => {
	const programContext = useContext(ProgramContext)
	const { institutionReq, setInstitutionReq, fetchInstitutionReq, setInstitutionReqParams } = programContext
	const [uploading, setUploading] = useState(false)
	//const inputUploadFileRef = useRef(null)
	let references = []

	useEffect(() => {
		setInstitutionReqParams({ programID: match.params.id })
	}, [])

	const FileIcon = ({ type }) => {
		switch (type) {
			case 'pdf':
				return <FilePdfOutlined />
			case 'doc':
				return <FileWordOutlined />
			case 'xls':
				return <FileExcelOutlined />
			case 'ppt':
				return <FilePptOutlined />
			case 'img':
				return <FileImageOutlined />
		}

		return <FileOutlined />
	}

	const groupByCategory = type => {
		switch (type) {
			case 'pdf':
				return 'pdf'
			case 'docx':
			case 'doc':
				return 'doc'
			case 'xlsx':
			case 'xls':
				return 'xls'
			case 'pptx':
			case 'ppt':
				return 'ppt'
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'bmp':
				return 'img'
		}

		return 'file'
	}
	//

	return (
		<Fragment>
			{institutionReq.isLoading ? (
				<Spin />
			) : (
				institutionReq.data.map((req, index) => (
					<Fragment key={index}>
						<Row>
							<Col span={3}>
								{/* <Checkbox checked={req.institutionRequirement !== null && req.institutionRequirement.isChecked} /> */}
								{req.institutionRequirement === null ||
								(req.institutionRequirement !== null && req.institutionRequirement.isChecked === '0') ? (
									<Popover title='Menunggu Konfirmasi'>
										<InfoCircleOutlined style={{ color: 'orange' }} />
									</Popover>
								) : req.institutionRequirement !== null && req.institutionRequirement.isChecked === '1' ? (
									<Popover title='Terkonfirmasi'>
										<CheckCircleOutlined style={{ color: 'green' }} />
									</Popover>
								) : req.institutionRequirement !== null && req.institutionRequirement.isChecked === '2' ? (
									<Popover title='Ditolak'>
										<CloseCircleOutlined style={{ color: 'red' }} />
									</Popover>
								) : null}
							</Col>

							<Col span={21}>
								<p>
									{req.description}{' '}
									<Popover
										title='Aturan Jenis Berkas'
										content={
											<div>
												{_.uniq(req.fileType.map(file => groupByCategory(file))).map((file, index) => (
													<FileIcon type={file} key={index} />
												))}
											</div>
										}>
										{' '}
										<QuestionCircleOutlined />
									</Popover>
								</p>

								<Row gutter={[4, 4]}>
									{req.isNeedFile && (
										<Fragment>
											<Col span={12}>
												<Button
													loading={uploading}
													block
													style={{ fontSize: '12px' }}
													onClick={() => (references[index] ? references[index].click() : null)}>
													{req.institutionRequirement !== null && req.institutionRequirement.requirementFile.length > 0
														? 'Ubah File'
														: 'Tambah File'}{' '}
												</Button>

												<input
													type='file'
													ref={ref => (references[index] = ref)}
													style={{ display: 'none' }}
													onChange={e => {
														if (!uploading) {
															setUploading(true)
															API.changeProgramRequirement({ file: e.target.files[0], programRequirementID: req.ID })
																.then(() => {
																	notification.success({ message: 'Sukses upload berkas' })
																	fetchInstitutionReq()
																	setUploading(false)
																})
																.catch(e => {
																	notification.error({
																		message: `Gagal upload berkas, pastikan jenis berkas sesuai dengan persyaratan: ${req.fileType.join(
																			', '
																		)} `,
																	})
																	setUploading(false)
																})
														}
													}}
												/>
											</Col>
											{req.institutionRequirement !== null && req.institutionRequirement.requirementFile.length > 0 && (
												<Col span={12}>
													<a href={req.institutionRequirement.requirementFile[0].realPath} target='_blank'>
														<Button block style={{ fontSize: '12px' }}>
															Download File
														</Button>
													</a>
												</Col>
											)}
										</Fragment>
									)}
								</Row>
							</Col>
						</Row>
						<Divider />
					</Fragment>
				))
			)}
		</Fragment>
	)
}

export default Requirements
