import React, { Fragment, useContext } from 'react'
import { PageHeader, Avatar, Popover, Space, Divider } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { NavLink, Link } from 'react-router-dom'
import DudiLogo from '../Assets/Images/Mitras Dudi Logo.png'
import { AuthContext } from '../Context/AuthContext'

import gravatar from 'gravatar'

const NewHeader = ({ isRoot = false }) => {
	const authContext = useContext(AuthContext)

	const { profile } = authContext

	return (
		<div className='new_home_section-1__header'>
			<a href='/'>
				<img src={require('../Assets/Images/kemdikbud.png')} alt='Logo Kemdikbud' />
			</a>

			{isRoot ? (
				<div className='new_home_section-1__header_link_root'>
					<a href='#beasiswa'>
						<span className='new_home_section-1__header_link'>Program</span>
					</a>
					<a href='#pedoman'>
						<span className='new_home_section-1__header_link'>Pedoman</span>
					</a>
					<a href='#tentang'>
						<span className='new_home_section-1__header_link'>Tentang</span>
					</a>
				</div>
			) : (
				<div className='new_home_section-1__header_link_root'>
					<a href='/#beasiswa'>
						<span className='new_home_section-1__header_link'>Program</span>
					</a>
					<a href='/#pedoman'>
						<span className='new_home_section-1__header_link'>Pedoman</span>
					</a>
					<a href='/#tentang'>
						<span className='new_home_section-1__header_link'>Tentang</span>
					</a>
				</div>
			)}
			<div className='dudi-nav-item' style={{ paddingLeft: '0' }}>
				<div style={{ marginRight: '1rem', color: '#fff' }} className='dudi-nav-item-institution'>
					<strong>{profile === null ? null : profile.managerName}</strong>
					{profile === null ? null : (
						<img
							src={gravatar.url(profile.email, {
								r: 'g',
								d: 'retro',
								s: '100',
							})}
							style={{ marginLeft: '1rem', height: 32, width: 32, objectFit: 'cover', borderRadius: 16 }}
						/>
					)}
				</div>

				{profile === null ? null : (
					<Popover
						placement='bottomRight'
						trigger='click'
						content={
							<Fragment>
								<div className='popover-item-institution'>
									<strong>{profile === null ? null : profile.managerName}</strong>
									<Divider />
								</div>
								<div>
									{
										profile === null ? <Link to='/login'>Login</Link> : null
										// <Link to='/profile'>Profile</Link>
									}{' '}
								</div>
								<div>{profile === null ? <Link to='/register'>Daftar</Link> : null} </div>
								<div>
									{profile === null ? null : (
										<a
											href='#!'
											onClick={() => {
												localStorage.clear()
												sessionStorage.clear()
												window.location.href = '/'
											}}>
											Keluar
										</a>
									)}
								</div>
								{isRoot ? (
									<div>{profile === null ? null : <a href='#beasiswa'>Program</a>}</div>
								) : (
									<div>{profile === null ? null : <a href='/#beasiswa'>Program</a>}</div>
								)}
							</Fragment>
						}>
						<MenuOutlined style={{ color: '#FFF' }} />
					</Popover>
				)}
			</div>
			{/* <div>
				<a href='#cara-pendaftaran'>
					<button className='new_home_section-1__header_button'>
						<span className='new_home_section-1__header_link'>Tata Cara Pendaftaran</span>
					</button>
				</a>
			</div> */}
		</div>
	)
}

export default NewHeader
