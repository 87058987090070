import React from 'react'
import 'antd/dist/antd.css'
import './App.scss'
import { CaretUpOutlined } from '@ant-design/icons'

import routes from './Utils/routes'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Layout, BackTop, Button } from 'antd'
import NotFound from './Pages/404Page'

import { AnimatePresence } from 'framer-motion'

const App = () => {
	return (
		<Router>
			<AnimatePresence exitBeforeEnter>
				<Layout className='dudi-layout'>
					<Switch>
						{!localStorage.getItem('@user_token') && !sessionStorage.getItem('@user_token')
							? routes.publicRoutes.map((route, index) => (
									<Route exact path={route.route} component={route.component} key={index} />
							  ))
							: routes.protectedRoutes.map((route, index) => (
									<Route exact={index === 0} path={route.route} component={route.component} key={index} />
							  ))}
						<Route component={NotFound} key='last' />
					</Switch>
					{/* <BackTop>
						<Button className='back-to-top-btn'>
							<CaretUpOutlined />
						</Button>
					</BackTop> */}
				</Layout>
			</AnimatePresence>
		</Router>
	)
}

export default App
