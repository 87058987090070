import React from 'react'

const Label = ({ text }) => {
	return (
		<div
			style={{
				height: 54,
				width: 54,
				borderRadius: 54 / 2,
				background: '#FCD108',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: '#25284E',
				position: 'absolute',
				top: 6,
				left: 6,
				zIndex: 2,
			}}>
			<span style={{ fontSize: 32, fontWeight: 'bold' }}>{text}</span>
		</div>
	)
}

export default Label
