import Login from '../Pages/Auth/Login'
import Register from '../Pages/Auth/Register'
import Home from '../Pages/Home'
import MainProgram from '../Pages/Program'
import Detail from '../Pages/Program/Detail'
import NewDetail from '../Pages/Program/NewDetail'
import Profile from '../Pages/Profile'
import Program from '../Pages/Profile/Program'
import BudgetPlanDetail from '../Pages/Program/BudgetPlanDetail'

const publicRoutes = [
	{
		title: 'Home',
		route: '/program',
		component: MainProgram,
	},
	{
		title: 'Redirect',
		route: '/',
		component: Home,
	},

	{
		title: 'Register',
		route: '/register',
		component: Register,
	},
	{
		title: 'Login',
		route: '/login',
		component: Login,
	},
	{
		title: 'Program',
		route: '/program/:id',
		component: Detail,
	},
	{
		title: 'Home',
		route: '/post/:id',
		component: NewDetail,
	},
]

const protectedRoutes = [
	{
		title: 'Own Program',
		route: '/profile/program',
		component: Program,
	},
	{
		title: 'Profile',
		route: '/profile',
		component: Profile,
	},

	{
		title: 'Register',
		route: '/register',
		component: Register,
	},
	{
		title: 'Login',
		route: '/login',
		component: Login,
	},
	{
		title: 'Budget Plan Detail',
		route: '/program/:id/budget-plan/:budgetPlanID',
		component: BudgetPlanDetail,
	},
	{
		title: 'Program',
		route: '/program/:id',
		component: Detail,
	},
	{
		title: 'Home',
		route: '/post/:id',
		component: NewDetail,
	},

	{
		title: 'Home',
		route: '/program',
		component: MainProgram,
	},
	{
		title: 'Redirect',
		route: '/',
		component: Home,
	},
]

export default {
	protectedRoutes,
	publicRoutes,
}
