import React from 'react'
import { Row, Card, Skeleton, Divider, Button, Badge } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CheckCircleFilled } from '@ant-design/icons'
import thousandSeparator from '../Utils/thousandSeparator'

let img = `https://loremflickr.com/320/240/school,indonesia?random=${Math.random(Math.floor()) * 100}`

const ProgramCard = ({ title, description, disabled = false, image = img, navigateTo, nominal }) => {
	return (
		<Card
			loading={false}
			bordered={false}
			className='program-card main-card-dudi'
			cover={
				<div className='dudi-card-img-back'>
					<img alt='cover image' src={image} className='card-image-dudi' />
				</div>
			}>
			<Meta title={title} />
			<div className='dudi-card-desc'>{description}</div>
			<Divider style={{ borderColor: 'black' }} />
			<h3 className='program-price'>Rp. {thousandSeparator(nominal)}</h3>
			<Link to={navigateTo}>
				<Button disabled={disabled} className='card-button'>
					Detail
				</Button>
			</Link>
		</Card>
	)
}

ProgramCard.defaultProps = {
	title: 'Judul',
}

ProgramCard.propTypes = {
	title: PropTypes.string.isRequired,
}

export default ProgramCard
