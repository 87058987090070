import React, { useContext, Fragment, useState, useEffect, useRef } from 'react'
import Header from '../../Components/Header'
import CustomLayout from '../../Components/CustomLayout'
import {
	Row,
	Col,
	Divider,
	Avatar,
	Card,
	Badge,
	Modal,
	Input,
	notification,
	Select,
	Button,
	Alert,
	Descriptions,
} from 'antd'
import { CheckCircleFilled, EditOutlined, FileOutlined, FileFilled, FileAddOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../Context/AuthContext'
import LocationPicker from 'react-location-picker'
import API from '../../Services/API'
import { ProgramContext } from '../../Context/ProgramContext'
import { motion } from 'framer-motion'
import moment from 'moment'
import 'moment/locale/id'
import _ from 'lodash'

const Profile = ({ history, match }) => {
	const authContext = useContext(AuthContext)
	const programContext = useContext(ProgramContext)
	const [showModalEdit, setShowModalEdit] = useState(false)
	const [profileEdit, setProfileEdit] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [banksForm, setBanksForm] = useState({ bankID: '', number: '', file: null, institutionID: '' })
	const [legalForm, setLegalForm] = useState({ number: '', date: '', file: null })
	const [npwpForm, setNpwpForm] = useState({ number: '', file: null })
	const [postalCode, setPostalCode] = useState('')
	const [identification, setIdentification] = useState(null)
	// const inputImageRef = useRef(null)

	const { profile, setProfile, getUserProfile } = authContext
	const { banks } = programContext

	useEffect(() => {
		if (profile) {
			setProfileEdit(profile)
			if (profile.institutionBank) {
				setBanksForm(profile.institutionBank)
			} else {
				setBanksForm({ ...banksForm, institutionID: profile.ID })
			}

			if (profile.institutionLegal) {
				setLegalForm(profile.institutionLegal)
			}

			if (profile.institutionAddress) {
				setPostalCode(profile.institutionAddress.postalCode)
			}
			if (profile.institutionNPWP) {
				setNpwpForm(profile.institutionNPWP)
			}
		}
	}, [profile])

	const onChangeForm = e => setProfileEdit({ ...profileEdit, [e.target.name]: e.target.value })
	const onChangeFormFile = e => setProfileEdit({ ...profileEdit, [e.target.name]: e.target.files[0] })

	const onUpdateProfile = async () => {
		if (!submitting && profileEdit) {
			try {
				setSubmitting(true)
				if (!Object.values(banksForm).includes(null)) {
					await API.updateProfileBank(banksForm.institutionID, { ...banksForm })
					notification.success({ message: 'Sukses memperbarui data bank' })
				} else {
					notification.error({
						message:
							'Gagal memperbarui data bank. Pastikan Anda sudah mengisi semua informasi bank (No. Rekening, Nama Bank, Rekening Koran)',
					})
				}

				if (!Object.values(legalForm).includes(null)) {
					await API.updateInstitutionLegal(legalForm)
					notification.success({ message: 'Sukses memperbarui data Akta / SK pendirian / Perubahan terakhir lembaga' })
				} else {
					notification.error({
						message:
							'Gagal memperbarui Akta / SK pendirian / Perubahan terakhir lembaga. Pastikan Anda sudah mengisi semua informasi Akta (Nomor, Tanggal, Berkas)',
					})
				}

				if (!Object.values(npwpForm).includes(null)) {
					await API.updateInstitutionNpwp(npwpForm)
					notification.success({ message: 'Sukses memperbarui data NPWP' })
				} else {
					notification.error({
						message: 'Gagal memperbarui NPWP. Pastikan Anda sudah mengisi semua informasi NPWP (Nomor, Berkas)',
					})
				}

				if (postalCode.trim() !== '') {
					await API.updateInstitutionPostalCode({ postalCode })
				}

				if (identification !== null) {
					await API.updateInstitutionIdentification({ file: identification })
				}

				await API.updateProfile(profileEdit.ID, { ...profileEdit, code: profileEdit.institutionCode.code })

				notification.success({ message: 'Sukses update profil' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				notification.error({ message: 'Gagal Update Profil' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<Header title='Profile' onBack={() => (history.action === 'POP' ? history.push('/') : history.goBack())} />

			<Modal
				width='70vw'
				confirmLoading={submitting}
				title='Edit Informasi Lembaga'
				visible={showModalEdit}
				onCancel={() => setShowModalEdit(false)}
				onOk={onUpdateProfile}>
				{profileEdit !== null && (
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12}>
							<span>Nama</span>
							<Input name='name' placeholder='Masukkan Nama Lembaga' value={profileEdit.name} onChange={onChangeForm} />
							<br />
							<br />
							<span>Kode Lembaga</span>
							<Input
								name='code'
								placeholder='Masukkan Kode Lembaga'
								value={profileEdit.institutionCode.code}
								onChange={e =>
									setProfileEdit({
										...profileEdit,
										institutionCode: { ...profileEdit.institutionCode, code: e.target.value },
									})
								}
							/>
							<br />
							<br />
							<span>Nama Pengelola</span>
							<Input
								placeholder='Masukkan Nama Pengelola'
								value={profileEdit.managerName}
								onChange={e => setProfileEdit({ ...profileEdit, managerName: e.target.value })}
							/>
							<br />
							<br />
							<span>No. Telpon</span>
							<Input
								placeholder='Masukkan Nama Pengelola'
								value={profileEdit.phone}
								onChange={e => setProfileEdit({ ...profileEdit, phone: e.target.value })}
							/>
							<br />
							<br />
							<span>Email</span>
							<Input
								type='email'
								placeholder='Email'
								value={profileEdit.email}
								onChange={e => setProfileEdit({ ...profileEdit, email: e.target.value })}
							/>
							<br />
							<br />
							<span>Kode Pos</span>
							<Input placeholder='Kode Pos' value={postalCode} onChange={e => setPostalCode(e.target.value)} />
							<br />
							<br />
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span>Kota</span>

								<Select
									value={profileEdit.cityID}
									onChange={value => setProfileEdit({ ...profileEdit, cityID: value })}
									showSearch
									filterOption={false}
									style={{
										overflow: 'hidden',
										width: '100%',
									}}
									onSearch={_.debounce(
										val =>
											programContext.setCities({
												...programContext.cities,
												parameters: { ...programContext.cities.parameters, keyword: val },
											}),
										500
									)}>
									{programContext.cities.data.map(city => (
										<Select.Option key={city.ID} value={city.ID}>
											{city.name}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
						{/* // */}
						<Col xs={24} md={12}>
							<br />
							<span>No. NPWP</span>
							<Input
								placeholder='Masukkan No. NPWP'
								onChange={e => setNpwpForm({ ...npwpForm, number: e.target.value })}
							/>
							<br />
							<br />
							<span>Berkas NPWP</span>
							<Input
								type='file'
								placeholder='Masukkan Berkas NPWP'
								onChange={e => setNpwpForm({ ...npwpForm, file: e.target.files[0] })}
							/>
							<br />
							<br />
							<span>KTP</span>
							<Input
								type='file'
								placeholder='Masukkan Berkas KTP'
								onChange={e => setIdentification(e.target.files[0])}
							/>
							<br />
							<br />
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span>Bank</span>
								<Select
									placeholder='Pilih Bank'
									value={banksForm.bankID}
									onChange={val => setBanksForm({ ...banksForm, bankID: val })}>
									{banks.data.map((bank, index) => (
										<Select.Option value={bank.ID} key={bank.ID}>
											{bank.name}
										</Select.Option>
									))}
								</Select>
							</div>
							<br />
							<span>No. Rekening</span>
							<Input
								name='number'
								placeholder='Masukkan No. Rekening'
								value={banksForm.number}
								onChange={e => setBanksForm({ ...banksForm, number: e.target.value })}
							/>
							<br />
							<br />
							<span>Rekening Koran</span>
							<Input
								type='file'
								placeholder='Masukkan Rekening Koran'
								onChange={e => setBanksForm({ ...banksForm, file: e.target.files[0] })}
							/>
							<br />
							<br />
							<span>Nomor Akta / SK pendirian / Perubahan terakhir lembaga</span>
							<Input
								name='number'
								placeholder='Masukkan Nomor Akta / SK pendirian / Perubahan terakhir lembaga'
								value={legalForm.number}
								onChange={e => setLegalForm({ ...legalForm, number: e.target.value })}
							/>
							<br />
							<br />
							<span>Tanggal Akta / SK pendirian / Perubahan terakhir lembaga</span>
							<Input
								type='date'
								name='date'
								placeholder='Tanggal Nomor Akta / SK pendirian / Perubahan terakhir lembaga'
								value={legalForm.date}
								onChange={e => setLegalForm({ ...legalForm, date: e.target.value })}
							/>
							<br />
							<br />
							<span>Berkas Akta / SK pendirian / Perubahan terakhir lembaga</span>
							<Input
								type='file'
								name='file'
								placeholder='Berkas Nomor Akta / SK pendirian / Perubahan terakhir lembaga'
								onChange={e => setLegalForm({ ...legalForm, file: e.target.files[0] })}
							/>
							<br />
						</Col>
					</Row>
				)}
			</Modal>

			<CustomLayout>
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
					<Card className='main-card-dudi profile-big-card '>
						<Row justify='center'>
							<Col>
								<Avatar
									src='https://upload.wikimedia.org/wikipedia/commons/9/9c/Logo_of_Ministry_of_Education_and_Culture_of_Republic_of_Indonesia.svg'
									size={240}
									className='dudi-avatar_profile'
									key='avatar'
								/>
								{/* <EditOutlined onClick={() => inputImageRef.current.click()} /> */}
								{/* <input type='file' name='avatar' ref={inputImageRef} style={{ display: 'none' }} onChange={(e) => } /> */}
							</Col>
						</Row>
						<br />
						<Row justify='center'>
							<Col>
								{profile === null ? null : profile.verified ? (
									<Badge count={<CheckCircleFilled className='verified-badge' />}>
										<h1 style={{ paddingRight: '6px' }}>{profile.name}</h1>
									</Badge>
								) : (
									<h1 style={{ paddingRight: '6px' }}>{profile.name}</h1>
								)}
							</Col>
						</Row>

						<Divider />

						<Alert
							message='Harap melengkapi data untuk dapat menerima program bantuan'
							type='success'
							closable
							style={{ width: '80%', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' }}
						/>
						<br />
						{profile === null ? null : (
							<Fragment>
								{/* 
								<Row>
									<Col span={6}>Kode Pos</Col>
									<Col span={16}>: {profile.codePos ? profile.codePos : '-'}</Col>
								</Row> */}

								<Descriptions title='Informasi Lembaga' bordered>
									<Descriptions.Item label='Nama Lembaga'>{profile.name}</Descriptions.Item>
									<Descriptions.Item label='Kode Lembaga'>
										{profile.institutionCode ? profile.institutionCode.code : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='NPWP'>
										{profile.institutionNPWP ? profile.institutionNPWP.number : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='Pengelola Lembaga'>{profile.managerName}</Descriptions.Item>
									<Descriptions.Item label='Email Pengelola'>{profile.email}</Descriptions.Item>
									<Descriptions.Item label='No. Telpon'>{profile.phone}</Descriptions.Item>
									<Descriptions.Item label='No. Rekening'>
										{profile.institutionBank ? profile.institutionBank.number : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='Nama Bank'>
										{profile.institutionBank ? profile.institutionBank.bank.name : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='Kode Pos'>
										{profile.institutionAddress ? profile.institutionAddress.postalCode : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='Nomor Akta / SK pendirian / Perubahan terakhir lembaga'>
										{profile.institutionLegal ? profile.institutionLegal.number : '-'}
									</Descriptions.Item>
									<Descriptions.Item label='Tanggal Akta / SK pendirian / Perubahan terakhir lembaga'>
										{profile.institutionLegal
											? moment(profile.institutionLegal.date, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY')
											: '-'}
									</Descriptions.Item>
								</Descriptions>

								<br />
								<Button onClick={() => setShowModalEdit(true)} style={{ float: 'right' }}>
									Edit Lembaga
								</Button>
								<br />

								<Divider />

								<Row gutter={[16, 16]} align='top' justify='start'>
									<Col span={4}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											{profile.institutionNPWP ? (
												<a href={profile.institutionNPWP.realPath} target='_blank'>
													<Card>
														<FileFilled />
													</Card>
												</a>
											) : (
												<Card>
													<FileAddOutlined />
												</Card>
											)}
											<br />
											<span>NPWP</span>
										</div>
									</Col>
									<Col span={4}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											{profile.institutionIdentification ? (
												<a href={profile.institutionIdentification.realPath} target='_blank'>
													<Card>
														<FileFilled />
													</Card>
												</a>
											) : (
												<Card>
													<FileAddOutlined />
												</Card>
											)}

											<br />
											<span>Berkas KTP</span>
										</div>
									</Col>
									<Col span={4}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											{profile.institutionBank && profile.institutionBank.pathFile ? (
												<a href={profile.institutionBank.pathFile} target='_blank'>
													<Card>
														<FileFilled />
													</Card>
												</a>
											) : (
												<Card>
													<FileAddOutlined />
												</Card>
											)}

											<br />
											<span>Rekeing Koran</span>
										</div>
									</Col>

									<Col span={4}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											{profile.institutionLegal ? (
												<a href={profile.institutionLegal.realPath} target='_blank'>
													<Card>
														<FileFilled />
													</Card>
												</a>
											) : (
												<Card>
													<FileAddOutlined />
												</Card>
											)}

											<br />
											<span>File Akta / SK pendirian / Perubahan terakhir lembaga</span>
										</div>
									</Col>
								</Row>
							</Fragment>
						)}

						<Divider />

						<div style={{ width: '100%', marginTop: '32px' }}>
							{/* <h1>Foto Lembaga</h1>

						<img
							src={`https://1.bp.blogspot.com/-G5uVndLjN0w/WFJ97LzMjAI/AAAAAAAAA04/PMbvyaZG1z0eqG40TP84YA4-8z32Jys4QCLcB/s1600/google-office-front-view.jpg`}
							alt=''
							style={{ width: '100%', borderRadius: '10px', height: '400px', objectFit: 'cover' }}
						/> */}
						</div>

						<div style={{ width: '100%', marginTop: '32px' }}>
							<h1>Lokasi</h1>
							{profile === null ? null : (
								<LocationPicker
									zoom={16}
									containerElement={<div style={{ height: '100%' }}></div>}
									mapElement={<div style={{ height: '500px' }} />}
									defaultPosition={{ lat: parseFloat(profile.latitude), lng: parseFloat(profile.longitude) }}
								/>
							)}
						</div>

						{/* <Divider /> */}
					</Card>
				</motion.div>
			</CustomLayout>
		</div>
	)
}

export default Profile
