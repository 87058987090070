import { Button, Row, Col, Carousel } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AuthContext } from '../../Context/AuthContext'
import Slider from 'react-slick'
// import {  } from  'antd'

import './Home.scss'
import Card from './components/Card'
import data from './data'
import dataProgram from './dataProgram'
import Label from './components/Label'
import CarouselImage from './components/CarouselImage'
import Footer from '../../Components/Footer'
import API from '../../Services/API'
import NewHeader from '../../Components/NewHeader'

let easing = [0.6, -0.05, 0.01, 0.99]
const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 4,
	adaptiveHeight: true,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
	],
}

const HomePage = ({ history }) => {
	const auhtcontext = useContext(AuthContext)
	const { profile } = auhtcontext

	const [posts, setPosts] = useState({ data: [], isLoading: false })
	const [booklets, setBooklets] = useState({ data: [], isLoading: false })

	const fetchPosts = async () => {
		try {
			const response = await API.getPosts()
			setPosts({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}
	const fetchBooklet = async () => {
		try {
			const response = await API.getBooklets()
			setBooklets({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchPosts()
		fetchBooklet()
	}, [])

	return (
		<div className='new_home'>
			<div className='new_home_section-1'>
				<div style={{ maxWidth: 1536, margin: '0 auto' }}>
					<NewHeader isRoot />
					<Row style={{ position: 'absolute' }}>
						<Col span={12}>
							<motion.div
								initial={{ opacity: 0, x: -100 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: -100 }}
								transition={{ duration: 0.8, ease: easing }}
								className='beranda-container-title'>
								<span className='minititle'>PROGRAM</span>

								<div style={{ color: '#fff', textTransform: 'uppercase' }}>
									Beasiswa Pendidikan Vokasi Indonesia 2021
								</div>

								{profile === null && (
									<div style={{ display: 'flex' }}>
										<Button
											onClick={() => history.push('/login')}
											className='dudi-register-btn-home'
											style={{ marginTop: '1rem' }}>
											Masuk
										</Button>
										<div style={{ width: '1rem' }}></div>
										<Button
											onClick={() => history.push('/register')}
											className='dudi-register-btn-home'
											style={{ marginTop: '1rem' }}>
											Daftar
										</Button>
									</div>
								)}
							</motion.div>
						</Col>
					</Row>
				</div>
			</div>

			{/* ===SECTION2=== */}

			<div className='new_home_section-2' id='tentang'>
				<div style={{ maxWidth: 1536, margin: '0 auto' }}>
					<div className='new_home_section-2-container'>
						<img src={require('../../Assets/Images/kemdikbud.png')} alt='Logo Kemdikbud' />
						<p className='new_home_section-2__text'>
							Program Non-gelar <em>(non-degree)</em> pendidikan vokasi disediakan untuk memfasilitasi peningkatan
							kapasitas SDM vokasi di Indonesia dan menghasilkan produk hasil riset terapan yang berbasis pada kebutuhan
							dunia usaha dan dunia industri (DUDI). Program ini merupakan upaya Kementerian Pendidikan dan Kebudayaan
							(Kemendikbud) dalam meningkatkan daya saing bangsa melalui pendanaan dari Lembaga Pengelola Dana
							Pendidikan (LPDP)
						</p>
					</div>
				</div>
			</div>

			{/* ===SECTION3=== */}
			{posts.isLoading ? null : (
				<div className='new_home_section-3' id='beasiswa'>
					<div style={{ maxWidth: 1536, margin: '0 auto' }}>
						<div className='new_home_section-3__header'>
							<div className='new_home_section-3__header_icon_group'>
								<img
									className='logo-kemdikbud'
									src={require('../../Assets/Images/kemdikbud.png')}
									alt='Logo Kemdikbud'
								/>
								{/* <img className='logo-lpdp' src={require('../../Assets/Images/logo-setjen.png')} alt='Logo LPDP' /> */}
							</div>
							<div className='new_home_section-3__header_text_group'>
								<p>BEASISWA PENDIDIKAN NON DEGREE PROGRAM VOKASI 2021</p>
							</div>
						</div>

						<div className='card__container'>
							<Row
								gutter={[
									{ xs: 16, sm: 32, lg: 64 },
									{ xs: 16, sm: 32, lg: 64 },
								]}>
								{posts.data.map((d, index) => (
									<Col sm={24} md={12} lg={12} key={index}>
										<Card data={d} index={index} />
									</Col>
								))}
							</Row>
						</div>
					</div>
				</div>
			)}

			{/* ===SECTION5=== */}
			<div className='new_home_section-5' id='cara-pendaftaran'>
				<div style={{ maxWidth: 1536, margin: '0 auto' }}>
					<div className='new_home_section-5__header'>
						<div className='new_home_section-5__header_text_group'>
							<p>ALUR PENDAFTARAN</p>
							<p>PROGRAM PENDIDIKAN VOKASI INDONESIA</p>
							<p>2021</p>
						</div>
					</div>

					<div className='alur_pendaftaran_container'>
						<div className='bg-bar'></div>

						<div className='alur_pendaftaran_container_item'>
							<Label text='1' />
							<span>DAFTAR AKUN</span>
						</div>
						<div className='alur_pendaftaran_container_item'>
							<Label text='2' />
							<span style={{ fontSize: 18 }}>MELENGKAPI DATA DIRI</span>
						</div>
						<div className='alur_pendaftaran_container_item'>
							<Label text='3' />
							<span>MASUK AKUN</span>
						</div>
						<div className='alur_pendaftaran_container_item'>
							<Label text='4' />
							<span>PILIH PROGRAM</span>
						</div>
						<div className='alur_pendaftaran_container_item'>
							<Label text='5' />
							<span>AJUKAN PROGRAM</span>
						</div>
						<div className='alur_pendaftaran_container_item'>
							<Label text='6' />
							<span style={{ fontSize: 18 }}>LENGKAPI PERSYARATAN DAN BERKAS</span>
						</div>
					</div>
				</div>
			</div>

			{/* ===SECTION7=== */}
			{booklets.isLoading ? null : (
				<div className='new_home_section-7' id='pedoman'>
					<div style={{ maxWidth: 1536, margin: '0 auto' }}>
						<div className='new_home_section-7__header'>
							<div className='new_home_section-7__header_text_group'>
								<p>PEDOMAN</p>
								<p>PROGRAM</p>
							</div>
						</div>

						<br />

						<div>
							<Slider {...settings}>
								{booklets.data.map((carousel, index) => (
									<CarouselImage data={carousel} key={index} />
								))}
							</Slider>
						</div>
					</div>
				</div>
			)}

			{/* ===SECTION6=== */}
			<div className='new_home_section-6'>
				<div style={{ maxWidth: 1536, margin: '0 auto' }}>
					<div className='new_home_section-6__header'>
						<div className='new_home_section-6__header_text_group'>
							<p>PAKET LINK & MATCH</p>
						</div>
					</div>

					<div className='new_home_section-6__header'>
						<div className='new_home_section-6__header_text_under'>
							<p className='text_8i'>8+i</p>
							<div className='new_home_section-6__header_text_under__container'>
								<p className='new_home_section-6__header_text_under__title'>Poin paket Link and Match</p>
								<p className='new_home_section-6__header_text_under__caption'>
									Keterlibatan dunia usaha dan dunia industri (DUDI) di segala aspek penyelenggaraan pendidikan vokasi
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* ===SECTION5=== */}
				<div style={{ background: '#25284e' }}>
					<div className='new_home_section-6__content'>
						<div className='new_home_section-6__content__left_child'>
							<p className='item_number'>1</p>
							<div className='item_content'>
								<p className='item_content_title'>Kurikulum disusun bersama dan berstandar DUDI</p>

								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									(Penguatan aspek softskills dan karakter keberkerjaan untuk melengkapi aspek 1 hardskills yang sesuai
									kebutuhan DUDI)
								</p>
							</div>
						</div>
						<div className='new_home_section-6__content__right_child'>
							<p className='item_number'>2</p>
							<div className='item_content'>
								<p className='item_content_title'>Pembelajaran Berbasis Project Riil dari DUDI (PBL) sejak awal</p>
								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									(Memastikan hardskills akan disertai softskills dan karakter kesiapan kerja yang 2kuat)
								</p>
							</div>
						</div>
					</div>
					{/* ============== */}
					<div className='new_home_section-6__content' style={{ marginTop: 16 }}>
						<div className='new_home_section-6__content__left_child'>
							<p className='item_number'>3</p>
							<div className='item_content'>
								<p className='item_content_title'>
									Jumlah dan peran guru/ dosen dari industri expert dari DUDI ditingkatkan secara signifikan
								</p>

								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									(Minimal mencapai 50 jam/ 3 semester/ prodi)
								</p>
							</div>
						</div>
						<div
							className='new_home_section-6__content__right_child'
							style={{ minWidth: 'unset', maxWidth: 'unset', width: 435 }}>
							<p className='item_number'>4</p>
							<div className='item_content'>
								<p className='item_content_title'>Magang/ praktek kerja industri (Prakerin)</p>
								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									Minimal 1 semester
								</p>
							</div>
						</div>
					</div>
					{/* ============== */}
					<div className='new_home_section-6__content' style={{ marginTop: 16 }}>
						<div className='new_home_section-6__content__left_child'>
							<p className='item_number'>5</p>
							<div className='item_content'>
								<p className='item_content_title'>Sertifikasi kompetensi yang sesuaistandar dan kebutuhan DUDI</p>

								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									(Bagi lulusan dan guru/ dosen/ 5 pengajar)
								</p>
							</div>
						</div>
						<div
							className='new_home_section-6__content__right_child'
							style={{ minWidth: 'unset', maxWidth: 'unset', width: 530 }}>
							<p className='item_number'>6</p>
							<div className='item_content'>
								<p className='item_content_title'>
									Guru/ dosen/ pengajar/ secara rutin mendapatkan update teknologi dan training dari DUDI untuk pengajar
								</p>
								{/* <p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									Minimal 1 semester
								</p> */}
							</div>
						</div>
					</div>
					{/* ============== */}
					<div className='new_home_section-6__content' style={{ marginTop: 16 }}>
						<div className='new_home_section-6__content__left_child' style={{ zIndex: 3 }}>
							<p className='item_number'>7</p>
							<div className='item_content'>
								<p className='item_content_title'>
									Riset terapan yang bermula dari kasus atau kebutuhan nyata di DUDI dan masyarakat
								</p>

								<p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									(Sebagai basis Teaching Industry/ Teaching Factory/ berkolaborasi dengan DUDI dan stakeholder)
								</p>
							</div>
						</div>
						<div
							className='new_home_section-6__content__right_child'
							style={{ minWidth: 'unset', maxWidth: 'unset', width: 420, zIndex: 2 }}>
							<p className='item_number'>8</p>
							<div className='item_content'>
								<p className='item_content_title'>Komitmen serapan lulusan oleh DUDI</p>
								{/* <p style={{ margin: 0, marginTop: 12 }} className='item_content_caption'>
									Minimal 1 semester
								</p> */}
							</div>
						</div>
						<div className='new_home_section-6__content__left_child' style={{ marginLeft: '-95px', paddingLeft: 120 }}>
							<p className='item_number'>i</p>
							<div className='item_content'>
								<p className='item_content_title'>
									Beasiswa atau ikatan dinas dari DUDI untuk siswa/ mahasiswa serta donasi dari DUDI dalam bentuk
									peralatan laboratorium, atau dalam bentuk lainnya, bagi pendidikan vokasi
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default HomePage
