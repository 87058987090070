import React, { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, Input, Select, Checkbox, notification } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import RegisterIcon from '../../Assets/Images/register-icon.png'
import LocationPicker from 'react-location-picker'
import Geocode from 'react-geocode'
import { ProgramContext } from '../../Context/ProgramContext'
import { useContext } from 'react'
import API from '../../Services/API'
import _ from 'lodash'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)

const Register = ({ history }) => {
	const programContext = useContext(ProgramContext)

	const [position, setPosition] = useState({ lat: '', lng: '' })
	const [addressValue, setAddressValue] = useState('')
	const [addressResult, setAddressResult] = useState('')
	const [addresArray, setAddresArray] = useState([])
	const [formError, setFormError] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	const [formValue, setFormValue] = useState({
		name: '',
		email: '',
		phone: '',
		code: '',
		managerName: '',
		cityID: '',
		password: '',
	})

	const getAddressByLatLng = async (lat, lng) => {
		const geo = await Geocode.fromLatLng(lat, lng)
		setPosition(geo.results[0].geometry.location)
		setAddresArray(geo.results)
	}

	const getAddress = async () => {
		if (addressValue !== '') {
			const geo = await Geocode.fromAddress(addressValue)
			setAddresArray(geo.results)
		}
	}

	useEffect(() => {
		getAddress()
	}, [addressValue])

	useEffect(() => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				position => {
					setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
					getAddressByLatLng(position.coords.latitude, position.coords.longitude)
				},
				error => {
					setPosition({ lat: -6.1753871, lng: 106.8249641 })
					getAddressByLatLng(-6.1753871, 106.8249641)
				}
			)
		} else {
			console.log('Not Available')
			setPosition({ lat: -6.1753871, lng: 106.8249641 })
			getAddressByLatLng(-6.1753871, 106.8249641)
		}
	}, [])

	const onRegister = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createInstitution({
						...formValue,
						latitude: position.lat,
						longitude: position.lng,
					})
					resolve(response)
					setSubmitting(false)
				} catch (error) {
					setSubmitting(false)
					setFormError(error.response.data)
					reject(error)
				}
			}
		})
	}

	const onSearch = useCallback(value => setAddressValue(value), [])

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			style={{
				position: 'absolute',
				width: '70%',
				transform: 'translate(-50%, 0%)',
				top: '15%',
				left: '50%',
				marginBottom: 40,
			}}>
			<Card
				style={{ boxShadow: '0 0 15px -7px #9b9b9b', borderRadius: 4, overflow: 'hidden' }}
				bodyStyle={{ padding: 0 }}>
				<Row>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ background: '#14213d' }}>
						<ArrowLeftOutlined style={{ margin: '16px 0 0 16px', color: '#fff' }} onClick={() => history.push('/')} />
						<div style={{ padding: 40 }}>
							<div style={{ position: 'relative' }}>
								<p style={{ textAlign: 'center', fontWeight: 700, fontSize: 22, color: 'white' }}>
									Daftarkan Diri Anda
								</p>
								<span
									style={{
										borderBottom: '3px solid white',
										width: '30%',
										position: 'absolute',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}></span>
							</div>

							<br />

							<p style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: 300 }}>
								Cek kembali data diri dan Lembaga Anda sebelum menekan tombol daftar
							</p>

							<Form
								name={'Login'}
								style={{
									marginTop: 40,
								}}>
								<Form.Item
									validateStatus={formError && formError.errors.managerName ? 'error' : 'validating'}
									name='pengelola'
									rules={[{ required: false, message: 'Mohon isi nama Lengkap' }]}>
									<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Nama Lengkap</label>
									<Input
										onChange={e => setFormValue({ ...formValue, managerName: e.target.value })}
										placeholder={'Masukkan Nama Lengkap'}
										style={{
											padding: 10,
											borderRadius: 4,
										}}
									/>
									<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
										{formError && formError.errors?.managerName ? formError.errors?.managerName[0] ?? '' : null}
									</span>
								</Form.Item>
								<Form.Item
									validateStatus={formError && formError.errors.name ? 'error' : 'validating'}
									name='name'
									rules={[{ required: false, message: 'Mohon isi nama lembaga Anda!' }]}>
									<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Nama Lembaga</label>
									<Input
										onChange={e => setFormValue({ ...formValue, name: e.target.value })}
										placeholder={'Masukkan Nama Lembaga Anda'}
										style={{
											padding: 10,
											borderRadius: 4,
										}}
									/>
									<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
										{formError && formError.errors?.name ? formError.errors?.name[0] ?? '' : null}
									</span>
								</Form.Item>

								<Form.Item
									validateStatus={formError && formError.errors.code ? 'error' : 'validating'}
									name='code'
									rules={[{ required: false, message: 'Mohon isi Kode DIKTI / NPSN!' }]}>
									<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Kode DIKTI / NPSN</label>
									<Input
										onChange={e => setFormValue({ ...formValue, code: e.target.value })}
										placeholder={'Masukkan Kode DIKTI / NPSN'}
										style={{
											padding: 10,
											borderRadius: 4,
										}}
									/>
									<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
										{formError && formError.errors?.code ? formError.errors?.code[0] ?? '' : null}
									</span>
								</Form.Item>

								<Row gutter={[8, 0]}>
									<Col sm={12}>
										<Form.Item
											validateStatus={formError && formError.errors.name ? 'error' : 'validating'}
											name='email'
											rules={[{ required: false, message: 'Mohon isi email lembaga Anda!' }]}>
											<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Email</label>
											<Input
												onChange={e => setFormValue({ ...formValue, email: e.target.value })}
												placeholder={'Masukkan Email Lembaga Anda'}
												style={{
													padding: 10,
													borderRadius: 4,
												}}
											/>
											<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
												{formError && formError.errors?.email ? formError.errors?.email[0] ?? '' : null}
											</span>
										</Form.Item>
									</Col>
									<Col sm={12}>
										<Form.Item
											validateStatus={formError && formError.errors.phone ? 'error' : 'validating'}
											name='phone'
											rules={[{ required: false, message: 'Mohon isi telepon lembaga Anda!' }]}>
											<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Telepon</label>
											<Input
												onChange={e => setFormValue({ ...formValue, phone: e.target.value })}
												placeholder={'Masukkan Telepon Lembaga Anda'}
												style={{
													padding: 10,
													borderRadius: 4,
												}}
											/>
											<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
												{formError && formError.errors?.phone ? formError.errors?.phone[0] ?? '' : null}
											</span>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item
									validateStatus={formError && formError.errors.cityID ? 'error' : 'validating'}
									name='city'
									rules={[{ required: false, message: 'Please Select City!' }]}>
									<Row gutter={[8, 0]}>
										<Col xs={24} md={24}>
											<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Kota</label>
											<Select
												onChange={value => setFormValue({ ...formValue, cityID: value })}
												showSearch
												filterOption={false}
												onSearch={_.debounce(
													val =>
														programContext.setCities({
															...programContext.cities,
															parameters: { ...programContext.cities.parameters, keyword: val },
														}),
													500
												)}
												size={'large'}
												style={{
													overflow: 'hidden',
													fontSize: 12,
													width: '100%',
													borderRadius: 4,
												}}>
												{programContext.cities.data.map(city => (
													<Select.Option key={city.ID} value={city.ID}>
														{city.name}
													</Select.Option>
												))}
											</Select>
											<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
												{formError && formError.errors?.cityID ? formError.errors?.cityID[0] ?? '' : null}
											</span>
										</Col>
									</Row>
								</Form.Item>

								<Form.Item
									validateStatus={
										formError && (formError.errors.latitude || formError.errors.latitude) ? 'error' : 'validating'
									}
									name='location'
									rules={[{ required: false, message: 'Please Select Location!' }]}>
									<Input.Group>
										<Row gutter={[8, 0]}>
											<Col xs={24} md={24}>
												<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Pilih Alamat</label>
												<Select
													dropdownStyle={{ width: '100%', overflow: 'hidden' }}
													onChange={(value, b) => {
														setAddressResult(value)
														setPosition(addresArray[b.key].geometry.location)
													}}
													onSearch={_.debounce(onSearch, 1000)}
													placeholder='Pilih Alamat'
													defaultActiveFirstOption
													// value={addressResult}
													showSearch
													filterOption={false}
													size={'large'}
													style={{
														overflow: 'hidden',
														fontSize: 12,
														width: '100%',
														borderRadius: 4,
													}}>
													{addresArray.map((addr, index) => (
														<Select.Option key={index} value={addr.formatted_address}>
															{addr.formatted_address}
														</Select.Option>
													))}
												</Select>
												<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
													{formError && (formError.errors.latitude || formError.errors.latitude)
														? 'Please input location'
														: null}
												</span>
											</Col>
										</Row>
									</Input.Group>
								</Form.Item>
								<Form.Item>
									<Input.Group>
										<Row gutter={[8, 0]}>
											<Col xs={24} md={24}>
												{position.lat !== '' && position.lng !== '' && (
													<LocationPicker
														zoom={16}
														containerElement={<div style={{ height: '100%' }}></div>}
														mapElement={<div style={{ height: '400px' }} />}
														defaultPosition={position}
														onChange={e => {
															setPosition({ lat: e.position.lat, lng: e.position.lng })
															getAddressByLatLng(e.position.lat, e.position.lng)
														}}
													/>
												)}
											</Col>
										</Row>
									</Input.Group>
									{/* <span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>asdasd</span> */}
								</Form.Item>

								<Row gutter={[8, 0]}>
									<Col sm={24}>
										<Form.Item
											validateStatus={formError && formError.errors.password ? 'error' : 'validating'}
											name='password'
											rules={[{ required: false, message: 'Please input your password!' }]}>
											<label style={{ color: 'white', fontSize: 12, fontWeight: 300 }}>Password</label>
											<Input.Password
												onChange={e => setFormValue({ ...formValue, password: e.target.value })}
												placeholder={'Masukkan Password Anda'}
												style={{
													padding: 10,
													borderRadius: 4,
												}}
											/>
											<span style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
												{formError && formError.errors?.password ? formError.errors?.password[0] ?? '' : null}
											</span>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item name='remember'>
									<Checkbox
										checked={true}
										style={{
											fontSize: 12,
											color: 'white',
											fontWeight: 300,
										}}>
										Saya menerima syarat dan ketentuan
									</Checkbox>
								</Form.Item>

								<p style={{ textAlign: 'center', marginTop: 20 }}>
									<button
										disabled={submitting}
										className={'dudi-button-white'}
										onClick={() =>
											onRegister().then(() => {
												history.push('/login')
												notification.success({ message: 'Berhasil register, silahkan Login' })
											})
										}>
										DAFTAR
									</button>
								</p>
							</Form>
						</div>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ position: 'relative' }}>
						<div
							style={{
								width: '100%',
								height: '100%',
							}}>
							<div
								style={{
									paddingTop: '20%',
									paddingBottom: '20%',
									paddingLeft: '5%',
									paddingRight: '5%',
								}}>
								<div style={{ position: 'relative' }}>
									<h2 style={{ textAlign: 'center', color: '#14213d' }}>Selamat Datang</h2>

									<span
										style={{
											borderBottom: '3px solid white',
											width: '30%',
											position: 'absolute',
											left: '50%',
											transform: 'translate(-50%, -50%)',
										}}></span>
								</div>

								<p style={{ textAlign: 'center' }}>
									<img src={RegisterIcon} width={'250'} alt={'Register Program Dudi'} />
								</p>

								<p style={{ textAlign: 'center', color: '#14213d', marginTop: 40, lineHeight: 2, fontWeight: 300 }}>
									Silakan isi form yang ada di depan Anda untuk melakukan pendaftaran. Jika Anda sudah memiliki akun
									sebelumnya, silakan tekan tombol login untuk masuk ke akun Anda.
								</p>

								<p style={{ textAlign: 'center', color: 'white', marginTop: 40 }}>
									<Link to='/login'>
										<button className={'dudi-button'}>Masuk</button>
									</Link>
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
		</motion.div>
	)
}

export default Register
