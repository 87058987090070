import React, { useState, Fragment, useRef } from 'react'
import Header from '../../Components/Header'
import CustomLayout from '../../Components/CustomLayout'
import {
	Row,
	Col,
	Divider,
	Card,
	Spin,
	Avatar,
	Tag,
	Button,
	List,
	Tooltip,
	Tabs,
	Steps,
	Input,
	Modal,
	Form,
	notification,
	Empty,
} from 'antd'
import {
	CaretLeftFilled,
	FileOutlined,
	DownloadOutlined,
	FileWordOutlined,
	DollarCircleOutlined,
	FileTextOutlined,
	EditOutlined,
} from '@ant-design/icons'
import API from '../../Services/API'
import { useEffect, useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import { ProgramContext } from './../../Context/ProgramContext'
import ReactQuill from 'react-quill'
import { AuthContext } from '../../Context/AuthContext'
import thousandSeparator from '../../Utils/thousandSeparator'
import Requirements from './Components/Requirements'
import { motion } from 'framer-motion'
import HistoryAplicant from './Components/HistoryAplicant'

import { FileIcon, defaultStyles } from 'react-file-icon'

import moment from 'moment'
import HistoryBudget from './Components/HistoryBudget'
import NewHeader from '../../Components/NewHeader'

import 'react-quill/dist/quill.snow.css'

const today = moment()

const ProgramDetail = ({ match, history }) => {
	const programContext = useContext(ProgramContext)
	const authContext = useContext(AuthContext)
	const {
		programPhaseList,
		detailPhaseForm,
		setDetailPhaseForm,
		fetchProgramPhaseList,
		discussList,
		discuss,
		setDiscuss,
		createDiscuss,
		queryDiscuss,
		setQueryDiscuss,
		queryProgramPhase,
		setQueryProgramPhase,
	} = programContext
	const { institutionID, profile } = authContext
	const [detailProgram, setDetailProgram] = useState({ data: {}, isLoading: true })
	const [applicants, setApplicants] = useState({ data: [], isLoading: true, count: 0 })
	const [activeApplicants, setActiveApplicants] = useState({ data: [], isLoading: true, count: 0 })

	const [isModalActive, setIsModalActive] = useState(false)
	const [isFileModalActive, setIsFileModalActive] = useState(false)

	const [filesModal, setFileModal] = useState([])

	const [activeKey, setActiveKey] = useState('1')

	useEffect(() => {
		setActiveKey(programPhaseList.data.length > 0 ? '2' : '1')
	}, [programPhaseList.data])

	const getProgramDetail = async () => {
		try {
			const response = await API.getProgramDetail(match.params.id)
			setDetailProgram({ data: response.data, isLoading: false })
			setDiscuss({ ...discuss, programID: response.data.ID, accountID: institutionID })
			setQueryDiscuss({ ...queryDiscuss, programID: response.data.ID })
			setQueryProgramPhase({ ...queryProgramPhase, programID: match.params.id })
		} catch (error) {
			console.log(error)
			setDetailProgram({ ...detailProgram, isLoading: false })
		}
	}

	const getApplicants = async () => {
		try {
			setApplicants({ ...applicants, isLoading: true })
			const response = await API.getAplicants({ programID: match.params.id, confirmed: 1 })

			setApplicants({ data: response.data.list, isLoading: false, count: response.data.pagination.itemCount })
		} catch (error) {
			console.log(error)
			setApplicants({ ...applicants, isLoading: false })
		}
	}

	const getActiveApplicants = async () => {
		try {
			const response = await API.getAplicants({ programID: match.params.id })
			setActiveApplicants({ data: response.data.list, isLoading: true, count: response.data.pagination.itemCount })
		} catch (error) {
			setActiveApplicants({ ...activeApplicants, isLoading: false })
		}
	}

	useEffect(() => {
		getActiveApplicants()
		fetchProgramPhaseList(match.params.id)
	}, [])

	const onSubmit = async () => {
		try {
			if (detailPhaseForm.type == 'add') {
				const response = await API.createDetailProgress(detailPhaseForm)
				setDetailPhaseForm({
					...detailPhaseForm,
					nominal: '',
					update: '',
					problem: '',
					'receipts[]': [],
					'activityPhotos[]': [],
					'reports[]': [],
				})
			}

			if (detailPhaseForm.type == 'update') {
				const response = await API.updateDetailProgress(detailPhaseForm.programPhaseID, detailPhaseForm)
				setDetailPhaseForm({
					...detailPhaseForm,
					nominal: '',
					update: '',
					problem: '',
					'receipts[]': [],
					'activityPhotos[]': [],
					'reports[]': [],
				})
			}

			setIsModalActive(false)
			fetchProgramPhaseList()
		} catch (error) {
			// setIsModalActive(false)
			notification.error({ message: 'Gagal Update' })
		}
	}

	useEffect(() => {
		getProgramDetail()
		getApplicants()
	}, [])

	useEffect(() => {
		if (detailPhaseForm.type == 'update') {
			setIsModalActive(true)
		}
	}, [detailPhaseForm.type])

	return (
		<div
			style={{
				minHeight: '100vh',
				backgroundColor: '#25284e',
				backgroundImage: 'url("/assets/home-bg.png")',
				backgroundAttachment: 'fixed',
			}}>
			<div style={{ maxWidth: 1536, margin: '32px auto' }}>
				<NewHeader />
			</div>

			<CustomLayout>
				<Row gutter={[16, 16]} justify='center'>
					{detailProgram.isLoading ? (
						<Col>
							<Spin size='large' />
						</Col>
					) : (
						<Fragment>
							<Col xs={24} md={24} lg={18}>
								<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
									<Card className='program-card-container '>
										<Row>
											<Col xs={4} md={2}>
												<Avatar
													src='https://upload.wikimedia.org/wikipedia/commons/9/9c/Logo_of_Ministry_of_Education_and_Culture_of_Republic_of_Indonesia.svg'
													size='large'
													className='dudi-avatar-detail'
													style={{ height: '50px', width: '50px' }}
												/>
											</Col>

											<Col xs={20} md={22}>
												<div className='detail-program-title'>
													<h1>{detailProgram.data.name}</h1>
													{/* <h3 style={{ marginTop: -10 }}>
														{moment(detailProgram.data.start, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')} -{' '}
														{moment(detailProgram.data.expired, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}
													</h3> */}
													<p>{detailProgram.data.description}</p>
												</div>
											</Col>
										</Row>

										<div className='detail-program-desc-wrapper'>
											<ReactMarkdown source={detailProgram.data.detail} escapeHtml={false} />

											<div className='detail-program-desc-wrapper-footer'>
												<div className='detail-program-desc-wrapper-footer-item'>
													<h4>Jumlah Pendaftar</h4>
													<span className='dudi-tags nominal'>{activeApplicants.count}</span>
												</div>
												<div className='detail-program-desc-wrapper-footer-item'>
													<h4>Status</h4>
													<span
														className='dudi-tags status'
														style={{
															background:
																detailProgram.data.status === '1'
																	? '#ff70a6'
																	: detailProgram.data.status === '2'
																	? '#60d5fb'
																	: detailProgram.data.status === '3'
																	? '#60d5fb'
																	: '#F8F8F8',
														}}>
														<span>
															{detailProgram.data.status === '1'
																? 'Belum Mulai'
																: detailProgram.data.status === '2'
																? 'Dalam Proses'
																: detailProgram.data.status === '3'
																? 'Selesai'
																: null}
														</span>
													</span>
												</div>
												<div className='detail-program-desc-wrapper-footer-item'>
													<h4>Pengajuan</h4>

													{detailProgram.data.isApplied ? (
														<span
															className={`dudi-tags ${
																detailProgram.data.isOwned === null || detailProgram.data.isOwned === 0
																	? 'pending'
																	: detailProgram.data.isOwned === 1
																	? 'status'
																	: detailProgram.data.isOwned === 2
																	? 'ditolak'
																	: ''
															} `}>
															{detailProgram.data.isOwned === 1
																? 'Diterima'
																: detailProgram.data.isOwned === 2
																? 'Ditolak'
																: 'Menunggu Persetujuan'}
														</span>
													) : (
														<span
															onClick={async () => {
																if (detailProgram.data.status === '2') {
																	try {
																		await API.applyProgramApplicant({
																			programID: match.params.id,
																			institutionID: institutionID,
																		})
																		notification.success({ message: 'Sukses Ajukan Program' })
																		getProgramDetail()
																	} catch (error) {
																		if (error.code === 403) {
																			notification.error({
																				message: 'Silahkan Masuk Atau Daftar untuk mengajukan program',
																			})
																			return
																		}
																		notification.error({ message: 'Gagal Ajukan Program' })
																	}
																}
															}}
															className='dudi-tags nominal'
															style={{
																cursor: detailProgram.data.status !== '2' ? 'unset' : 'pointer',
																opacity: detailProgram.data.status !== '2' ? 0.5 : 1,
															}}>
															Ajukan
														</span>
													)}
												</div>
											</div>
										</div>
										<br />
										<CaretLeftFilled
											style={{ marginLeft: '2rem', fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}
											onClick={() =>
												history.action === 'POP' ? (window.location.href = '/#beasiswa') : history.goBack()
											}
										/>
									</Card>
								</motion.div>
								<br />
								{/* <Col xs={24}> */}
								{profile === null ? null : (
									<Card className='program-card-container'>
										<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
											<Tabs defaultActiveKey='1' activeKey={activeKey} onChange={key => setActiveKey(key)}>
												<Tabs.TabPane tab='Diskusi' key='1'>
													<List
														className='demo-loadmore-list'
														itemLayout='horizontal'
														dataSource={discussList.data}
														renderItem={item => (
															<List.Item>
																<List.Item.Meta
																	avatar={
																		<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
																			{item.account.accountName !== null
																				? item.account.accountName.substr(0, 1)
																				: item.account.institutionAccess !== null
																				? item.account.institutionAccess.institution.managerName.substr(0, 1)
																				: item.account.email.substr(0, 1)}
																		</Avatar>
																	}
																	title={
																		<span>
																			{item.account.accountName !== null
																				? item.account.accountName
																				: item.account.institutionAccess !== null
																				? item.account.institutionAccess.institution.managerName
																				: item.account.email}
																			<small style={{ marginLeft: 10 }}>
																				{moment(item.created, 'YYYY-MM-DD').fromNow()}
																			</small>
																		</span>
																	}
																	subtitle={'test'}
																	description={
																		<div>
																			<p>{item.comment}</p>
																			{item.replyDiscusses.length > 0 ? (
																				<List
																					className='demo-loadmore-list'
																					itemLayout='horizontal'
																					dataSource={item.replyDiscusses}
																					renderItem={items => (
																						<List.Item>
																							<List.Item.Meta
																								avatar={
																									<Avatar
																										style={{
																											backgroundColor: 'rgba(85,110,230,.25)',
																											color: '#213869',
																										}}>
																										{items.account.accountName !== null
																											? items.account.accountName.substr(0, 1)
																											: items.account.institutionAccess !== null
																											? items.account.institutionAccess.institution.managerName.substr(
																													0,
																													1
																											  )
																											: items.account.email.substr(0, 1)}
																									</Avatar>
																								}
																								title={
																									<span>
																										{items.account.accountName !== null
																											? items.account.accountName
																											: items.account.institutionAccess !== null
																											? items.account.institutionAccess.institution.managerName
																											: items.account.email}
																										<small style={{ marginLeft: 10 }}>
																											{moment(items.created, 'YYYY-MM-DD').fromNow()}
																										</small>
																									</span>
																								}
																								description={items.comment}
																							/>
																						</List.Item>
																					)}
																				/>
																			) : null}
																		</div>
																	}
																/>

																{/* <Tooltip title={'Jawab'}>
																<RollbackOutlined style={{ fontSize: 18, color: '#14213d' }} />
															</Tooltip> */}
															</List.Item>
														)}
													/>
													{discuss ? (
														<div>
															<Input.TextArea
																rows={4}
																value={discuss.comment}
																onChange={e => {
																	setDiscuss({ ...discuss, comment: e.target.value })
																}}
															/>
															<br />
															<br />
															<Button
																type='primary'
																onClick={() => {
																	createDiscuss()
																}}>
																Kirim Diskusi
															</Button>
														</div>
													) : (
														<Button
															type='primary'
															onClick={() => {
																setDiscuss(true)
															}}>
															Buat Diskusi
														</Button>
													)}
												</Tabs.TabPane>
												<Tabs.TabPane tab='Update' key='2'>
													{programPhaseList.isLoading ? (
														<p>Loading</p>
													) : (
														<Fragment>
															<br />
															<br />
															{programPhaseList.data.length > 0 ? (
																<Steps direction='vertical' size='small' current={0}>
																	{programPhaseList.data.map((data, index) => (
																		<Steps.Step
																			icon={<Avatar>{programPhaseList.data.length - index}</Avatar>}
																			title={data.deadline}
																			key={index}
																			description={
																				<Card
																					style={{
																						boxShadow: '0 0 15px -7px #9b9b9b',
																						borderRadius: 4,
																						overflow: 'hidden',
																						position: 'relative',
																					}}>
																					{data.status == 1 ? (
																						<Button
																							icon={<EditOutlined />}
																							style={{
																								position: 'absolute',
																								right: 20,
																								top: 20,
																							}}
																							onClick={() => {
																								setDetailPhaseForm({
																									...detailPhaseForm,
																									type: 'add',
																									programPhaseID: data.ID,
																								})
																								setIsModalActive(true)
																							}}>
																							Tambah Laporan
																						</Button>
																					) : data.status == 2 ? (
																						<Button
																							icon={<EditOutlined />}
																							style={{
																								position: 'absolute',
																								right: 20,
																								top: 20,
																							}}
																							onClick={() => {
																								setDetailPhaseForm({
																									...detailPhaseForm,
																									...data.detailProgramPhase,
																									type: 'update',
																									programPhaseID: data.ID,
																								})
																							}}>
																							Ubah Laporan
																						</Button>
																					) : null}
																					{!data.detailProgramPhase ? (
																						<div
																							style={{
																								display: 'flex',
																								width: '50%',
																								justifyContent: 'center',
																								alignItems: 'center',
																							}}>
																							<Empty
																								description='Belum submit laporan'
																								imageStyle={{ height: '70px' }}
																							/>
																						</div>
																					) : (
																						<Fragment>
																							<p style={{ fontWeight: 500, fontSize: 15 }}>Update Program :</p>
																							<div
																								dangerouslySetInnerHTML={{
																									__html: data.detailProgramPhase.update,
																								}}></div>

																							<br />

																							<p style={{ fontWeight: 500, fontSize: 15 }}>
																								Kendala Pembuatan Program :
																							</p>

																							<div
																								dangerouslySetInnerHTML={{
																									__html: data.detailProgramPhase.problem,
																								}}></div>
																						</Fragment>
																					)}

																					<br />

																					<Row>
																						<Col md={8}>
																							<h4>
																								<DollarCircleOutlined style={{ color: '#14213d' }} /> &nbsp; Nominal
																								Yang Diajukan
																							</h4>
																							<p>{data.nominal ? `Rp ${thousandSeparator(data.nominal)}` : 'Rp. 0'}</p>
																							<h4>
																								<DollarCircleOutlined style={{ color: '#14213d' }} /> &nbsp; Nominal
																								Terpakai
																							</h4>
																							<p>
																								{data.detailProgramPhase
																									? `Rp. ${thousandSeparator(data.detailProgramPhase.nominal)}`
																									: 'Rp. 0'}
																							</p>
																						</Col>
																						<Col md={8}>
																							<Tag color='processing'>
																								{data.status == 2
																									? 'Telah Selesai'
																									: data.status == 1
																									? 'Belum Dimulai'
																									: 'Terverivikasi'}
																							</Tag>
																						</Col>
																						<Col md={8}>
																							<h4>
																								<FileTextOutlined style={{ color: '#14213d' }} /> &nbsp; Unduh Berkas
																							</h4>
																							<Row gutter={[4, 4]}>
																								<Col key={index}>
																									<Button
																										type='primary'
																										shape='round'
																										size={'small'}
																										onClick={() => {
																											setIsFileModalActive(true)
																											setFileModal([
																												{
																													name: 'receipt',
																													realPath: data.receiptFileURL,
																												},
																											])
																										}}>
																										{'Bukti Transfer'}
																									</Button>
																								</Col>
																								{data.detailProgramPhase
																									? Object.keys(data.detailProgramPhase.fileProgramPhases).map(
																											(file, index) => (
																												<Col key={index}>
																													<Button
																														type='primary'
																														shape='round'
																														size={'small'}
																														onClick={() => {
																															setIsFileModalActive(true)
																															setFileModal(
																																data.detailProgramPhase.fileProgramPhases[file]
																															)
																														}}>
																														{file}
																													</Button>
																												</Col>
																											)
																									  )
																									: null}
																							</Row>
																						</Col>
																					</Row>
																				</Card>
																			}
																		/>
																	))}
																</Steps>
															) : (
																<Empty />
															)}
														</Fragment>
													)}
												</Tabs.TabPane>
												{detailProgram.data.historyApplicant.length > 0 && (
													<Tabs.TabPane tab='Riwayat' key='3'>
														<HistoryAplicant history={detailProgram.data.historyApplicant} />
													</Tabs.TabPane>
												)}

												<Tabs.TabPane tab='Laporan Kemajuan' key='4' disabled>
													<HistoryBudget id={match.params.id} />
												</Tabs.TabPane>
											</Tabs>
										</motion.div>
									</Card>
								)}

								{profile === null && (
									<Card className='program-card-container'>
										<h2>Diskusi</h2>
										<List
											className='demo-loadmore-list'
											itemLayout='horizontal'
											dataSource={discussList.data}
											renderItem={item => (
												<List.Item>
													<List.Item.Meta
														avatar={
															<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
																{item.account.accountName !== null
																	? item.account.accountName.substr(0, 1)
																	: item.account.institutionAccess !== null
																	? item.account.institutionAccess.institution.managerName.substr(0, 1)
																	: item.account.email.substr(0, 1)}
															</Avatar>
														}
														title={
															item.account.accountName !== null
																? item.account.accountName
																: item.account.institutionAccess !== null
																? item.account.institutionAccess.institution.managerName
																: item.account.email
														}
														description={
															<div>
																<p>{item.comment}</p>
																{item.replyDiscusses.length > 0 ? (
																	<List
																		className='demo-loadmore-list'
																		itemLayout='horizontal'
																		dataSource={item.replyDiscusses}
																		renderItem={items => (
																			<List.Item>
																				<List.Item.Meta
																					avatar={
																						<Avatar
																							style={{
																								backgroundColor: 'rgba(85,110,230,.25)',
																								color: '#213869',
																							}}>
																							{items.account.accountName !== null
																								? items.account.accountName.substr(0, 1)
																								: items.account.institutionAccess !== null
																								? items.account.institutionAccess.institution.managerName.substr(0, 1)
																								: items.account.email.substr(0, 1)}
																						</Avatar>
																					}
																					title={
																						items.account.accountName !== null
																							? items.account.accountName
																							: items.account.institutionAccess !== null
																							? items.account.institutionAccess.institution.managerName
																							: items.account.email
																					}
																					description={items.comment}
																				/>
																			</List.Item>
																		)}
																	/>
																) : null}
															</div>
														}
													/>

													{/* <Tooltip title={'Jawab'}>
																<RollbackOutlined style={{ fontSize: 18, color: '#14213d' }} />
															</Tooltip> */}
												</List.Item>
											)}
										/>
									</Card>
								)}

								{/* </Col> */}
							</Col>

							<Col xs={24} md={24} lg={6} flex={1}>
								<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
									<Card className='program-card-container'>
										<h4 style={{ fontWeight: 'bold' }}>Lampiran</h4>
										<Divider />

										<div style={{ maxHeight: 500, overflow: 'auto' }}>
											{detailProgram.data.programFiles.length === 0 ? (
												<Empty description='Belum ada lampiran' imageStyle={{ height: '70px' }} />
											) : (
												detailProgram.data.programFiles.map((item, index) => (
													<Row gutter={[8, 8]} key={index}>
														<Col span={2}>
															<FileIcon
																extension={item.name.split('.')[item.name.split('.').length - 1]}
																{...defaultStyles[item.name.split('.')[item.name.split('.').length - 1]]}
															/>
														</Col>

														<Col span={20}>
															<p style={{ overflow: 'hidden' }}>{item.name}</p>
														</Col>

														<Col span={2}>
															<Tooltip title='Lihat Berkas'>
																<a download href={item.realPath}>
																	<DownloadOutlined />
																</a>
															</Tooltip>
														</Col>
													</Row>
												))
											)}
										</div>
									</Card>
									<br />
									{profile !== null && (
										<Card className='program-card-container' style={{ maxHeight: 400, overflow: 'auto' }}>
											<h4 style={{ fontWeight: 'bold' }}>Unggah Persyaratan</h4>

											<Requirements match={match} />
										</Card>
									)}
								</motion.div>
							</Col>
						</Fragment>
					)}
				</Row>
				<Modal
					title='Tambah Laporan'
					visible={isModalActive}
					onOk={() => {
						onSubmit()
					}}
					onCancel={() => {
						setIsModalActive(false)
					}}>
					<Form>
						<Form.Item name='usedMoney' rules={[{ required: true, message: 'Mohon isi uang yang terpakai!' }]}>
							<label style={{ fontSize: 12, fontWeight: 300 }}>Uang Terpakai</label>
							<Input
								placeholder={'Rp. 100.000.000'}
								style={{
									padding: 10,
									borderRadius: 4,
								}}
								value={detailPhaseForm.nominal}
								onChange={e => {
									setDetailPhaseForm({
										...detailPhaseForm,
										nominal: e.target.value,
									})
								}}
							/>
						</Form.Item>
						<label style={{ fontSize: 12, fontWeight: 300 }}>Update</label>
						<ReactQuill
							theme='snow'
							value={detailPhaseForm.update ? detailPhaseForm.update : ''}
							onChange={e => {
								setDetailPhaseForm({
									...detailPhaseForm,
									update: e,
								})
							}}></ReactQuill>
						<br />
						<label style={{ fontSize: 12, fontWeight: 300 }}>Kendala</label>
						<ReactQuill
							theme='snow'
							value={detailPhaseForm.problem ? detailPhaseForm.problem : ''}
							onChange={e => {
								setDetailPhaseForm({
									...detailPhaseForm,
									problem: e,
								})
							}}></ReactQuill>
						<br />
						<br />
						<label style={{ fontSize: 12, fontWeight: 300 }}>Unggah Kwintansi</label> <br />
						<p>
							{detailPhaseForm['receipts[]'].length > 0 &&
								Array.from(detailPhaseForm['receipts[]'])
									.map(report => `${report.name}`)
									.join(', ')}
						</p>
						<Button
							style={{ width: '100%' }}
							onClick={e => {
								document.getElementById('upload-kwitansi').click()
							}}>
							Tambah Berkas
						</Button>
						<input
							type={'file'}
							multiple={'multiple'}
							style={{ display: 'none' }}
							id={'upload-kwitansi'}
							onChange={e => {
								if (e.target.files.length > 0) {
									setDetailPhaseForm({
										...detailPhaseForm,
										'receipts[]': e.target.files,
									})
								}
							}}
						/>
						<br />
						<br />
						<label style={{ fontSize: 12, fontWeight: 300 }}>Unggah Laporan</label> <br />
						<p>
							{detailPhaseForm['reports[]'].length > 0 &&
								Array.from(detailPhaseForm['reports[]'])
									.map(report => `${report.name}`)
									.join(', ')}
						</p>
						<Button
							style={{ width: '100%' }}
							onClick={e => {
								document.getElementById('upload-laporan').click()
							}}>
							Tambah Berkas
						</Button>
						<input
							type={'file'}
							multiple={'multiple'}
							style={{ display: 'none' }}
							id={'upload-laporan'}
							onChange={e => {
								if (e.target.files.length > 0) {
									setDetailPhaseForm({
										...detailPhaseForm,
										'reports[]': e.target.files,
									})
								}
							}}
						/>
						<br />
						<br />
						<label style={{ fontSize: 12, fontWeight: 300 }}>Unggah Foto Kegiatan</label> <br />
						<p>
							{detailPhaseForm['activityPhotos[]'].length > 0 &&
								Array.from(detailPhaseForm['activityPhotos[]'])
									.map(report => `${report.name}`)
									.join(', ')}
						</p>
						<Button
							style={{ width: '100%' }}
							onClick={e => {
								document.getElementById('upload-activity').click()
							}}>
							Tambah Berkas
						</Button>
						<input
							type={'file'}
							multiple={'multiple'}
							style={{ display: 'none' }}
							id={'upload-activity'}
							onChange={e => {
								if (e.target.files.length > 0) {
									setDetailPhaseForm({
										...detailPhaseForm,
										'activityPhotos[]': e.target.files,
									})
								}
							}}
						/>
						<br />
					</Form>
				</Modal>

				<Modal
					title='Unduh Berkas'
					visible={isFileModalActive}
					onOk={() => {
						setIsFileModalActive(false)
					}}
					onCancel={() => {
						setIsFileModalActive(false)
					}}>
					{filesModal.map((file, index) => (
						<Row gutter={[12, 12]} key={index}>
							<Col sm={4}>
								<Avatar
									size={40}
									icon={<FileWordOutlined />}
									style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}
								/>
							</Col>
							<Col sm={16} style={{ paddingTop: 15, paddingLeft: 17 }}>
								<a href={file.realPath} target='_blank'>
									<p style={{ fontSize: 14, fontWeight: 500 }}>{file.name}</p>
								</a>
							</Col>
							<Col sm={4} style={{ paddingTop: 15 }}>
								<a href={file.realPath} target='_blank'>
									<DownloadOutlined style={{ fontSize: 18 }} />
								</a>
							</Col>
						</Row>
					))}
				</Modal>
			</CustomLayout>
		</div>
	)
}

export default ProgramDetail
