import React, { useEffect, useState } from 'react'
import CustomLayout from '../../Components/CustomLayout'
import { motion } from 'framer-motion'
import Header from '../../Components/Header'
import { Button, Spin, Tabs } from 'antd'
import API from '../../Services/API'
import { useRouteMatch, useHistory } from 'react-router-dom'
import BudgetPlanTable from './Components/BudgetPlanTable'
import { ArrowLeftOutlined } from '@ant-design/icons'

let easing = [0.6, -0.05, 0.01, 0.99]

const { TabPane } = Tabs

const BudgetPlanDetail = () => {
	const match = useRouteMatch('/program/:id/budget-plan/:budgetPlanID')
	const history = useHistory()

	const [listOutput, setListOutput] = useState({ data: [], isLoading: true })
	const [historyDetail, setHistoryDetail] = useState({ data: null, isLoading: true })
	const [activeTabKey, setActiveTabKey] = useState(null)

	const fetchListOutput = async () => {
		try {
			const response = await API.getProgramBudgetOutput({
				applicantBudgetHistoryID: match.params.budgetPlanID,
				pageSize: 0,
				sort: 'asc',
				order: 'ID',
			})
			setListOutput({
				isLoading: false,
				data: response.data.list,
			})
			setActiveTabKey(response.data.list[0].ID)
		} catch (error) {
			console.log(error)
		}
	}

	const fetchDetail = async () => {
		try {
			const response = await API.getProgramBudgetHistoryDetail(match.params.budgetPlanID)
			setHistoryDetail({
				isLoading: false,
				data: response.data,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchListOutput()
		fetchDetail()
	}, [])

	return (
		<div>
			<Header title='Budget Plan Detail' />
			<CustomLayout>
				<motion.div
					initial={{ opacity: 0, x: -100 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: easing }}>
					{listOutput.isLoading ? (
						<div
							style={{
								display: 'flex',
								height: '40vh',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<Spin />
						</div>
					) : (
						<div style={{ width: '100%' }}>
							<div style={{ padding: '16px' }}>
								<ArrowLeftOutlined
									onClick={() => {
										history.goBack()
									}}
								/>
							</div>

							{listOutput.data.map(output => (
								<Button
									key={output.ID}
									style={{ border: 'none', color: activeTabKey === output.ID ? '#1890ff' : '#000' }}
									onClick={() => {
										setActiveTabKey(output.ID)
									}}>
									{output.name}
								</Button>
							))}

							<Tabs
								type='card'
								tabPosition='bottom'
								animated={true}
								style={{ background: '#fff' }}
								activeKey={`${activeTabKey}`}
								defaultActiveKey={`${activeTabKey}`}
								onChange={key => setActiveTabKey(key)}>
								{listOutput.data.map(output => (
									<TabPane tab={output.name} key={output.ID}>
										<div style={{ padding: 16 }}>
											<BudgetPlanTable programBudgetOutputID={output.ID} />
										</div>
									</TabPane>
								))}
							</Tabs>
						</div>
					)}
				</motion.div>
			</CustomLayout>
		</div>
	)
}

export default BudgetPlanDetail
