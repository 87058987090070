import React from 'react'

const CarouselImage = ({ data }) => {
	return (
		<div>
			<a href={data.fileURL} target='_blank' rel='noopener noreferrer'>
				<img src={data.previewURL} alt='Pedoman' className='carousel-image' />
			</a>
		</div>
	)
}

export default CarouselImage
