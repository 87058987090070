import React, { Fragment, useContext } from 'react'
import { PageHeader, Avatar, Popover, Space, Divider } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import { NavLink, Link } from 'react-router-dom'
import DudiLogo from '../Assets/Images/Mitras Dudi Logo.png'
import { AuthContext } from '../Context/AuthContext'

const Header = ({ title, subTitle, onBack, ...rest }) => {
	const authContext = useContext(AuthContext)

	const { profile } = authContext

	return (
		<header className='dudi-header'>
			<div className='dudi-header-wrapper'>
				<div className='dudi-header-wrapper-nav'>
					<img src={DudiLogo} alt='Mitras DUDI' className='dudi-nav-logo' />

					<ul className='dudi-nav-item'>
						<li>
							<NavLink exact to='/' activeClassName='header-link-active' style={{ color: 'black' }}>
								Beranda
							</NavLink>
						</li>
						<li>
							<NavLink to='/program' activeClassName='header-link-active' style={{ color: 'black' }}>
								Program
							</NavLink>
						</li>
					</ul>
				</div>

				<div className='dudi-nav-item' style={{ paddingLeft: '0' }}>
					<div style={{ marginRight: '1rem' }} className='dudi-nav-item-institution'>
						<strong>{profile === null ? null : profile.name}</strong>
						{profile === null ? null : (
							<Avatar
								src='https://upload.wikimedia.org/wikipedia/commons/9/9c/Logo_of_Ministry_of_Education_and_Culture_of_Republic_of_Indonesia.svg'
								style={{ marginLeft: '1rem' }}
							/>
						)}
					</div>

					{profile === null ? null : (
						<Popover
							placement='bottomRight'
							trigger='click'
							content={
								<Fragment>
									<div className='popover-item-institution'>
										<strong>{profile === null ? null : profile.name}</strong>
										<Divider />
									</div>
									<div>{profile === null ? <Link to='/login'>Login</Link> : <Link to='/profile'>Profile</Link>} </div>
									<div>{profile === null ? <Link to='/register'>Daftar</Link> : null} </div>
									<div>
										{profile === null ? null : (
											<a
												href='#!'
												onClick={() => {
													localStorage.clear()
													sessionStorage.clear()
													window.location.href = '/'
												}}>
												Keluar
											</a>
										)}
									</div>
									<div>{profile === null ? null : <Link to='/profile/program'>Program</Link>}</div>
								</Fragment>
							}>
							<MenuOutlined />
						</Popover>
					)}
				</div>
			</div>
		</header>
	)
}

export default Header
