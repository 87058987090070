import React from 'react'
import { Layout, Row, Col } from 'antd'

const CustomLayout = ({ children }) => {
	return (
		<Row justify='center' >
			<Col span={24} className='custom-layout'>
				<br />
				{children}
			</Col>
		</Row>
	)
}

export default CustomLayout
